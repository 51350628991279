import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { PermissionArgs, usePermissionCheck } from "@src/Hooks/permissionsCheck";
import { useSession } from "@src/Session";

type ProtectedRouteProps = {
  permissions: PermissionArgs;
  children?: ReactNode;
  redirectPath?: string;
};

export function ProtectedRoute({ permissions, redirectPath = "/", children }: ProtectedRouteProps) {
  const { allowed } = usePermissionCheck(permissions);

  if (!allowed && allowed != null) {
    return <Navigate to={redirectPath} replace />;
  }

  return allowed == null ? <></> : <>{children}</>;
}

type SettingsProtectedRoute = {
  relation: string;
  redirectPath?: string;
  children: ReactNode;
};

export function SettingsProtectedRoute({
  relation,
  children,
  redirectPath = "/"
}: SettingsProtectedRoute) {
  const { session } = useSession();
  const sessionMetadata = session?.identity?.metadata_public as { org?: string };
  const orgId = sessionMetadata?.org;
  const perms =
    orgId === "*"
      ? { relation: "superAdmin", objectId: "nearbyone", objectType: "envs" }
      : { relation, objectId: orgId, objectType: "orgs" };
  return (
    <ProtectedRoute permissions={perms} redirectPath={redirectPath}>
      {children}
    </ProtectedRoute>
  );
}

type ProtectedDashboard = {
  dashboard: string;
  redirectPath?: string;
  children: ReactNode;
};

export function ProtectedDashboard({
  dashboard,
  children,
  redirectPath = "/"
}: ProtectedDashboard) {
  const perms = { relation: "view", objectId: dashboard, objectType: "dashboards" };
  return (
    <ProtectedRoute permissions={perms} redirectPath={redirectPath}>
      {children}
    </ProtectedRoute>
  );
}
