export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601 formatted Date and Time */
  Time: any;
};

export type ApiTokens = {
  __typename?: "ApiTokens";
  googleMaps?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  mapbox?: Maybe<Scalars["String"]>;
};

export type AuditLogEntity = {
  id: Scalars["ID"];
};

export enum AuditLogEntityType {
  Blockchart = "BLOCKCHART",
  Chain = "CHAIN",
  Cloudresource = "CLOUDRESOURCE",
  Device = "DEVICE",
  Federationguest = "FEDERATIONGUEST",
  Federationhost = "FEDERATIONHOST",
  Org = "ORG",
  Provision = "PROVISION",
  Site = "SITE",
  Unknown = "UNKNOWN",
  User = "USER"
}

export type AuditLogEntry = {
  __typename?: "AuditLogEntry";
  entity?: Maybe<
    | BlockChart
    | Device
    | FederationGuest
    | FederationHost
    | GenericAuditLogEntity
    | Org
    | Provision
    | ServiceChain
    | Site
    | User
  >;
  entityType?: Maybe<AuditLogEntityType>;
  message?: Maybe<Scalars["String"]>;
  org?: Maybe<Org>;
  reason?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["Time"]>;
  type?: Maybe<AuditLogEventType>;
  user?: Maybe<User>;
};

export type AuditLogEntryEdge = {
  __typename?: "AuditLogEntryEdge";
  cursor: Scalars["String"];
  node?: Maybe<AuditLogEntry>;
};

export enum AuditLogEventType {
  Normal = "NORMAL",
  Unknown = "UNKNOWN",
  Warning = "WARNING"
}

export enum AuditLogKind {
  Block = "BLOCK",
  Chain = "CHAIN",
  Device = "DEVICE"
}

export type AuditLogs = {
  __typename?: "AuditLogs";
  edges: Array<Maybe<AuditLogEntryEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type BBox = {
  /** Northwesternmost point of a bounding box */
  nw: LatLngInput;
  /** Southeasternmost point of a bounding box */
  se: LatLngInput;
};

export type Block = {
  __typename?: "Block";
  chainRevision: Scalars["Int"];
  chart?: Maybe<BlockChart>;
  connections?: Maybe<Array<Maybe<Connection>>>;
  controls?: Maybe<Array<Maybe<BlockControl>>>;
  displayName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  logEndpoint?: Maybe<LogEndpoint>;
  logEndpoints?: Maybe<Array<Maybe<LogEndpoint>>>;
  resources?: Maybe<Array<Maybe<OktoResource>>>;
  selectedVersion?: Maybe<Scalars["String"]>;
  status?: Maybe<Status>;
  values?: Maybe<Scalars["String"]>;
};

export type BlockLogEndpointArgs = {
  hash: Scalars["String"];
  id: Scalars["ID"];
};

export type BlockChart = AuditLogEntity & {
  __typename?: "BlockChart";
  availableVersions?: Maybe<Array<Scalars["String"]>>;
  /** keywords field in Chart.yaml */
  categories?: Maybe<Array<Scalars["String"]>>;
  /** full contents of Chart.yaml */
  chartYaml?: Maybe<Scalars["String"]>;
  /** description field in Chart.yaml */
  description?: Maybe<Scalars["String"]>;
  /** annotations.displayName in Chart.yaml */
  displayName?: Maybe<Scalars["String"]>;
  /** Chart digest, used for client caching. Cannot fetch by ID! */
  id: Scalars["ID"];
  /** icon field in Chart.yaml */
  logoUrl?: Maybe<Scalars["String"]>;
  /** Unique chart name in Helm repo. name field in Chart.yaml */
  name: Scalars["String"];
  /** overrides.yaml, values.yaml if it doesn't exist */
  overridesYaml?: Maybe<Scalars["String"]>;
  /** values.json.schema parsed as json string. Introspected from overrides if file does not exist. Includes values as defaults */
  valuesSchema?: Maybe<Scalars["String"]>;
  /** annotations.vendor field in Chart.yaml */
  vendor?: Maybe<Scalars["String"]>;
  /** version field in Chart.yaml */
  version: Scalars["String"];
};

export type BlockConnectionInput = {
  /** Connection ID */
  connId: Scalars["ID"];
  /** Field name */
  name: Scalars["String"];
};

export type BlockControl = {
  __typename?: "BlockControl";
  displayName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  options?: Maybe<Array<Maybe<Scalars["String"]>>>;
  value?: Maybe<Scalars["String"]>;
};

export type BlockInput = {
  /** Chart name in Helm repo */
  blockChartName: Scalars["String"];
  /** Chart semantic version number */
  blockChartVersion: Scalars["String"];
  /** Display name of the block */
  displayName: Scalars["String"];
  /**
   * ID of a previously-deployed block to update.
   * Providing this parameter implies an update to an existing block,
   * whereas inputs with no ID will be treated as new blocks.
   */
  id?: InputMaybe<Scalars["ID"]>;
  /** Override values in YAML format */
  values: Scalars["String"];
};

export type ChartKey = {
  __typename?: "ChartKey";
  /** Unique chart name in Helm repo. name field in Chart.yaml */
  name: Scalars["String"];
  /** version field in Chart.yaml */
  version: Scalars["String"];
};

export type ChartMetaInput = {
  categories: Array<Scalars["String"]>;
  description: Scalars["String"];
  displayName: Scalars["String"];
  iconUrl: Scalars["String"];
  name: Scalars["String"];
  vendor: Scalars["String"];
  version: Scalars["String"];
};

export type ClientCredentials = {
  __typename?: "ClientCredentials";
  clientId: Scalars["String"];
  clientSecret: Scalars["String"];
};

export type CloudResourceChart = {
  __typename?: "CloudResourceChart";
  /** These are the last deployed values */
  latestConfig?: Maybe<Array<CloudResourceConfigField>>;
  /** Unique chart name in Helm repo. name field in Chart.yaml */
  name: Scalars["String"];
  /** This comes from the values yaml that come from the original chart */
  originalConfig?: Maybe<Array<CloudResourceConfigField>>;
  /** version field in Chart.yaml */
  version: Scalars["String"];
};

export type CloudResourceChartListing = {
  __typename?: "CloudResourceChartListing";
  displayName: Scalars["String"];
  key: ChartKey;
};

export type CloudResourceConfigField = {
  __typename?: "CloudResourceConfigField";
  label: Scalars["String"];
  redacted?: Maybe<Scalars["Boolean"]>;
  required: Scalars["Boolean"];
  type?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type CloudResourceConfigFieldInput = {
  label: Scalars["String"];
  value: Scalars["String"];
};

export type CloudResourceInput = {
  /** Chart name in Helm repo */
  chartName: Scalars["String"];
  /** Chart semantic version number */
  chartVersion: Scalars["String"];
  /** Array of key value pairs for the Device configuration */
  config: Array<CloudResourceConfigFieldInput>;
  /** Site selector for controller */
  controllerSite: Scalars["ID"];
  /** Site that device belongs to */
  deviceSite: Scalars["ID"];
  /** Display name of device to be shown in the UI */
  displayName: Scalars["String"];
  /** Geographical position of device */
  position: LatLngInput;
};

export type Connection = {
  __typename?: "Connection";
  category: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  kind: Scalars["String"];
  name: Scalars["String"];
  source: Scalars["String"];
};

export type ConnectionInput = {
  category: Scalars["String"];
  kind: Scalars["String"];
  name: Scalars["String"];
  source: Scalars["String"];
};

export type CreateUserResponse = {
  __typename?: "CreateUserResponse";
  error?: Maybe<Error>;
  user?: Maybe<User>;
};

export type DeployTargetInput = {
  host: Scalars["ID"];
  master: Scalars["ID"];
};

export type Device = AuditLogEntity & {
  __typename?: "Device";
  cloudResourceChart?: Maybe<CloudResourceChart>;
  connections?: Maybe<Array<Maybe<Connection>>>;
  displayName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  metadata?: Maybe<Scalars["String"]>;
  position?: Maybe<LatLng>;
  progress?: Maybe<Progress>;
  site?: Maybe<Scalars["String"]>;
  specs?: Maybe<Scalars["String"]>;
  status?: Maybe<DeviceStatus>;
  tags?: Maybe<Array<Scalars["String"]>>;
  telemetryUrl?: Maybe<Array<TelemetryUrl>>;
};

/** Properties of a new device to be registered */
export type DeviceInput = {
  /** List of connections (e.g. video streams, APIs) available on the device */
  connections?: InputMaybe<Array<ConnectionInput>>;
  /** Display name of device to be shown in the UI */
  displayName?: InputMaybe<Scalars["String"]>;
  /** Configuration file used to connect to Kubenetes Control Plane of a Cloudlet */
  kubeconfig?: InputMaybe<Scalars["String"]>;
  /** Geographical position of device */
  position?: InputMaybe<LatLngInput>;
  /** Site that device belongs to */
  site: Scalars["String"];
  /** List of tags that describe the device */
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type DevicesResult = {
  __typename?: "DevicesResult";
  devices?: Maybe<Array<Device>>;
  hits?: Maybe<Scalars["Int"]>;
};

export enum DeviceStatus {
  Deploying = "DEPLOYING",
  Error = "ERROR",
  Ready = "READY",
  Unknown = "UNKNOWN"
}

export type EditConnectionInput = {
  category: Scalars["String"];
  id: Scalars["ID"];
  kind: Scalars["String"];
  name: Scalars["String"];
  source: Scalars["String"];
};

export type EditDeviceInput = {
  connections?: InputMaybe<Array<EditConnectionInput>>;
  deletedConnections?: InputMaybe<Array<Scalars["ID"]>>;
  /** Display name of device to be shown in the UI */
  displayName?: InputMaybe<Scalars["String"]>;
  /** Geographical position of device */
  position?: InputMaybe<LatLngInput>;
  /** Site that device belongs to */
  site?: InputMaybe<Scalars["String"]>;
  /** List of tags that describe the device */
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type EditFederationGuestInput = {
  countryCode: Scalars["String"];
  id: Scalars["ID"];
  mcc: Scalars["String"];
  mncs: Array<Scalars["String"]>;
};

export type EditFederationHostInput = {
  clientId?: InputMaybe<Scalars["String"]>;
  clientSecret?: InputMaybe<Scalars["String"]>;
  countryCode: Scalars["String"];
  federationName: Scalars["String"];
  hostUrl: Scalars["String"];
  id: Scalars["ID"];
  mcc: Scalars["String"];
  mncs: Array<Scalars["String"]>;
  operatorName: Scalars["String"];
  siteId: Scalars["String"];
};

export enum EntityType {
  Block = "BLOCK",
  Device = "DEVICE",
  Unknown = "UNKNOWN"
}

export type Error = {
  __typename?: "Error";
  code?: Maybe<Scalars["Int"]>;
  message: Scalars["String"];
};

export type EventLogEntry = {
  __typename?: "EventLogEntry";
  message?: Maybe<Scalars["String"]>;
  reason?: Maybe<Scalars["String"]>;
  resource?: Maybe<OktoResource>;
  time?: Maybe<Scalars["Time"]>;
  type?: Maybe<EventLogType>;
};

export type EventLogEntryEdge = {
  __typename?: "EventLogEntryEdge";
  cursor: Scalars["String"];
  node?: Maybe<EventLogEntry>;
};

export type EventLogs = {
  __typename?: "EventLogs";
  edges: Array<Maybe<EventLogEntryEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export enum EventLogType {
  Normal = "NORMAL",
  Unknown = "UNKNOWN",
  Warning = "WARNING"
}

export type Federation = {
  __typename?: "Federation";
  countryCode?: Maybe<Scalars["String"]>;
  federationUrl?: Maybe<Scalars["String"]>;
  mcc?: Maybe<Scalars["String"]>;
  mncs?: Maybe<Array<Scalars["String"]>>;
  operatorName?: Maybe<Scalars["String"]>;
};

export type FederationGuest = AuditLogEntity & {
  __typename?: "FederationGuest";
  countryCode?: Maybe<Scalars["String"]>;
  federationName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  mcc?: Maybe<Scalars["String"]>;
  mncs?: Maybe<Array<Scalars["String"]>>;
  operatorName?: Maybe<Scalars["String"]>;
  parentSite?: Maybe<Scalars["String"]>;
  status?: Maybe<FederationPartnerStatus>;
};

export type FederationGuestInput = {
  countryCode: Scalars["String"];
  federationName: Scalars["String"];
  mcc: Scalars["String"];
  mncs: Array<Scalars["String"]>;
  operatorName: Scalars["String"];
  parentSite: Scalars["String"];
};

export type FederationHost = AuditLogEntity & {
  __typename?: "FederationHost";
  countryCode?: Maybe<Scalars["String"]>;
  federationName?: Maybe<Scalars["String"]>;
  hostUrl?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  mcc?: Maybe<Scalars["String"]>;
  mncs?: Maybe<Array<Scalars["String"]>>;
  operatorName?: Maybe<Scalars["String"]>;
  siteId?: Maybe<Scalars["String"]>;
  status?: Maybe<FederationPartnerStatus>;
};

export type FederationHostInput = {
  clientId: Scalars["String"];
  clientSecret: Scalars["String"];
  countryCode: Scalars["String"];
  federationName: Scalars["String"];
  hostUrl: Scalars["String"];
  mcc: Scalars["String"];
  mncs: Array<Scalars["String"]>;
  operatorName: Scalars["String"];
  siteId: Scalars["String"];
};

export type FederationInput = {
  countryCode: Scalars["String"];
  federationUrl: Scalars["String"];
  mcc: Scalars["String"];
  mncs: Array<Scalars["String"]>;
  operatorName: Scalars["String"];
};

export enum FederationPartnerStatus {
  Disabled = "DISABLED",
  Enabled = "ENABLED"
}

export type FieldParam = {
  __typename?: "FieldParam";
  name: Scalars["String"];
  value: Scalars["String"];
};

export type GenericAuditLogEntity = AuditLogEntity & {
  __typename?: "GenericAuditLogEntity";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type LatLng = {
  __typename?: "LatLng";
  lat: Scalars["Float"];
  lng: Scalars["Float"];
};

export type LatLngInput = {
  /** Degrees latitude */
  lat: Scalars["Float"];
  /** Degrees longitude */
  lng: Scalars["Float"];
};

export type LogEndpoint = {
  __typename?: "LogEndpoint";
  description?: Maybe<Scalars["String"]>;
  /** Hash of the log endpoint data hidden to the client */
  hash: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  source?: Maybe<LogSource>;
  sources?: Maybe<Array<Maybe<LogSource>>>;
};

export type LogEndpointSourceArgs = {
  id: Scalars["String"];
};

export type LogEntry = {
  __typename?: "LogEntry";
  log: Scalars["String"];
  metadata?: Maybe<Scalars["String"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export enum LogEventType {
  Normal = "NORMAL",
  Warning = "WARNING"
}

export type LogSource = {
  __typename?: "LogSource";
  id: Scalars["String"];
  logs?: Maybe<Array<Maybe<LogEntry>>>;
  name: Scalars["String"];
};

export type LogSourceLogsArgs = {
  before?: InputMaybe<Scalars["Boolean"]>;
  logFilterSimpleQuery?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  size: Scalars["Int"];
};

export type MapSettings = {
  __typename?: "MapSettings";
  center?: Maybe<LatLng>;
  zoom?: Maybe<Scalars["Int"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  cloudResource?: Maybe<Device>;
  createFederationGuest?: Maybe<NewFederationGuest>;
  createFederationHost?: Maybe<FederationHost>;
  createOrg?: Maybe<Org>;
  createSite?: Maybe<Site>;
  createUser?: Maybe<CreateUserResponse>;
  /** returns chart version that was removed */
  deleteBlockChart?: Maybe<Scalars["String"]>;
  deployServiceChain?: Maybe<ServiceChain>;
  device?: Maybe<Device>;
  /** remove the User from an org */
  dismissUser?: Maybe<Scalars["ID"]>;
  editDevice?: Maybe<Device>;
  editFederation?: Maybe<Org>;
  editFederationGuest?: Maybe<FederationGuest>;
  editFederationHost?: Maybe<FederationHost>;
  editOrg?: Maybe<Org>;
  editSite?: Maybe<Site>;
  generateRecoveryLink?: Maybe<RecoveryLink>;
  provision?: Maybe<Device>;
  publishBlockChart?: Maybe<BlockChart>;
  recruitUser?: Maybe<User>;
  removeDevice?: Maybe<Scalars["ID"]>;
  removeFederationGuest?: Maybe<Scalars["ID"]>;
  removeFederationHost?: Maybe<Scalars["ID"]>;
  removeOrg?: Maybe<Scalars["ID"]>;
  removeServiceChain?: Maybe<Scalars["ID"]>;
  removeSite?: Maybe<Scalars["ID"]>;
  /** delete the User entirely */
  removeUser?: Maybe<Scalars["ID"]>;
  rollbackServiceChain?: Maybe<ServiceChain>;
  setBlockRepo?: Maybe<Scalars["String"]>;
  setControlValue?: Maybe<BlockControl>;
  setCurrentOrg?: Maybe<User>;
  setCustomCompletions?: Maybe<Scalars["String"]>;
  setDefaultMapPosition?: Maybe<MapSettings>;
  setFederationGuestStatus?: Maybe<FederationGuest>;
  setProvisionRepo?: Maybe<Scalars["String"]>;
  setRole?: Maybe<User>;
  setTemplateRepo?: Maybe<Scalars["String"]>;
  updateCloudResource?: Maybe<Device>;
  updateServiceChain?: Maybe<ServiceChain>;
};

export type MutationCloudResourceArgs = {
  input: CloudResourceInput;
};

export type MutationCreateFederationGuestArgs = {
  guest: FederationGuestInput;
};

export type MutationCreateFederationHostArgs = {
  host: FederationHostInput;
};

export type MutationCreateOrgArgs = {
  org: OrgInput;
};

export type MutationCreateSiteArgs = {
  site: SiteInput;
};

export type MutationCreateUserArgs = {
  email: Scalars["String"];
  name: Scalars["String"];
  org: Scalars["ID"];
  role: Role;
};

export type MutationDeleteBlockChartArgs = {
  name: Scalars["String"];
  version: Scalars["String"];
};

export type MutationDeployServiceChainArgs = {
  blocks?: InputMaybe<Array<BlockInput>>;
  name: Scalars["String"];
};

export type MutationDeviceArgs = {
  dev: DeviceInput;
};

export type MutationDismissUserArgs = {
  orgId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationEditDeviceArgs = {
  device: EditDeviceInput;
  id: Scalars["ID"];
};

export type MutationEditFederationArgs = {
  fed: FederationInput;
  id: Scalars["ID"];
};

export type MutationEditFederationGuestArgs = {
  info: EditFederationGuestInput;
};

export type MutationEditFederationHostArgs = {
  host: EditFederationHostInput;
};

export type MutationEditOrgArgs = {
  id: Scalars["ID"];
  org: OrgInput;
};

export type MutationEditSiteArgs = {
  id: Scalars["ID"];
  site: SiteInput;
};

export type MutationGenerateRecoveryLinkArgs = {
  user: Scalars["ID"];
};

export type MutationProvisionArgs = {
  input: ProvisionInput;
};

export type MutationPublishBlockChartArgs = {
  input: PublishBlockChartInput;
};

export type MutationRecruitUserArgs = {
  org: Scalars["ID"];
  role: Role;
  user: Scalars["ID"];
};

export type MutationRemoveDeviceArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveFederationGuestArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveFederationHostArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveOrgArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveServiceChainArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveSiteArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveUserArgs = {
  id: Scalars["ID"];
};

export type MutationRollbackServiceChainArgs = {
  id: Scalars["ID"];
  revision: Scalars["Int"];
};

export type MutationSetBlockRepoArgs = {
  repo: RepoInput;
};

export type MutationSetControlValueArgs = {
  id: Scalars["ID"];
  value: Scalars["String"];
};

export type MutationSetCurrentOrgArgs = {
  org: Scalars["ID"];
};

export type MutationSetCustomCompletionsArgs = {
  completions: Scalars["String"];
};

export type MutationSetDefaultMapPositionArgs = {
  center: LatLngInput;
  zoom: Scalars["Int"];
};

export type MutationSetFederationGuestStatusArgs = {
  status: SetFederationGuestStatusInput;
};

export type MutationSetProvisionRepoArgs = {
  repo: RepoInput;
};

export type MutationSetRoleArgs = {
  org: Scalars["ID"];
  role: Role;
  user: Scalars["ID"];
};

export type MutationSetTemplateRepoArgs = {
  repo: RepoInput;
};

export type MutationUpdateCloudResourceArgs = {
  input: UpdateCloudResourceInput;
};

export type MutationUpdateServiceChainArgs = {
  blocks?: InputMaybe<Array<BlockInput>>;
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type NewFederationGuest = {
  __typename?: "NewFederationGuest";
  clientCredentials: ClientCredentials;
  federationGuest: FederationGuest;
};

export type OktoResource = {
  __typename?: "OktoResource";
  id: Scalars["ID"];
  kind?: Maybe<Scalars["String"]>;
  logs?: Maybe<Scalars["String"]>;
  manifest?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  namespace?: Maybe<Scalars["String"]>;
  status?: Maybe<Status>;
};

export type Org = AuditLogEntity &
  SiteTreeItem & {
    __typename?: "Org";
    description?: Maybe<Scalars["String"]>;
    /** fetch the devices of this site and all descendent sites */
    devices?: Maybe<DevicesResult>;
    displayName?: Maybe<Scalars["String"]>;
    federation?: Maybe<Federation>;
    id: Scalars["ID"];
    sites?: Maybe<Array<Maybe<Site>>>;
    users?: Maybe<Array<Maybe<User>>>;
  };

export type OrgInput = {
  description?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
};

export type OrgRole = {
  __typename?: "OrgRole";
  id: Scalars["ID"];
  org?: Maybe<Org>;
  role?: Maybe<Role>;
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor: Scalars["String"];
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
  startCursor: Scalars["String"];
};

export type PaginationInput = {
  afterCursor?: InputMaybe<Scalars["String"]>;
  beforeCursor?: InputMaybe<Scalars["String"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
};

export type Progress = {
  __typename?: "Progress";
  goal: Scalars["Int"];
  step: Scalars["Int"];
};

export type Provision = AuditLogEntity & {
  __typename?: "Provision";
  chart?: Maybe<ProvisionChart>;
  displayName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  inventory?: Maybe<Scalars["String"]>;
  lastUpdated?: Maybe<Scalars["Time"]>;
  logEndpoint?: Maybe<LogEndpoint>;
  logEndpoints?: Maybe<Array<Maybe<LogEndpoint>>>;
  phases?: Maybe<Array<Maybe<ProvisionPhase>>>;
  resources?: Maybe<Array<Maybe<OktoResource>>>;
  siteTreeItem?: Maybe<Org | Site>;
  status?: Maybe<DeviceStatus>;
};

export type ProvisionLogEndpointArgs = {
  hash: Scalars["String"];
  id: Scalars["ID"];
};

export type ProvisionChart = {
  __typename?: "ProvisionChart";
  /** Unique chart name in Helm repo. name field in Chart.yaml */
  name: Scalars["String"];
  /** phases parsed from values.yaml e.g. baremetal software etc. */
  phases?: Maybe<Array<Maybe<ProvisionPhase>>>;
  /** version field in Chart.yaml */
  version: Scalars["String"];
};

export type ProvisionInput = {
  /** Chart name in Helm repo */
  chartName: Scalars["String"];
  /** Chart semantic version number */
  chartVersion: Scalars["String"];
  /** Display name of device to be shown in the UI */
  displayName: Scalars["String"];
  /** Geographical position of device */
  position: LatLngInput;
  /** Site selector for Provisioner block deployment */
  provisionerSite: Scalars["ID"];
  provisionInventory: Scalars["String"];
  /** Site that device belongs to */
  site: Scalars["ID"];
};

export type ProvisionPhase = {
  __typename?: "ProvisionPhase";
  enabled?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  progress?: Maybe<Progress>;
  resources?: Maybe<Array<Maybe<OktoResource>>>;
  values?: Maybe<Scalars["String"]>;
};

export type PublishBlockChartInput = {
  chartMeta: ChartMetaInput;
  overridesYaml?: InputMaybe<Scalars["String"]>;
  templateName: Scalars["String"];
  templateVersion?: InputMaybe<Scalars["String"]>;
  valuesYaml: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  apiTokens?: Maybe<ApiTokens>;
  /** Return logs for a subject's Org between the start and end times */
  auditLogs?: Maybe<AuditLogs>;
  blockChart?: Maybe<BlockChart>;
  /** Return the latest versions of all Block Charts in the registry */
  blockCharts?: Maybe<Array<Maybe<BlockChart>>>;
  /** Return the values of a cloudResource provision chart */
  cloudResourceChart?: Maybe<CloudResourceChart>;
  /** Return the latest versions of all cloudResource Charts in the registry */
  cloudResourceCharts: Array<Maybe<CloudResourceChartListing>>;
  connections?: Maybe<Array<Maybe<Connection>>>;
  customCompletions?: Maybe<Scalars["String"]>;
  devices?: Maybe<DevicesResult>;
  /** Return logs for okto resources between the start and end times */
  eventLogs?: Maybe<EventLogs>;
  /** Return the federation guest with the given ID */
  federationGuest?: Maybe<FederationGuest>;
  /** Return a list of the federation guests */
  federationGuests?: Maybe<Array<Maybe<FederationGuest>>>;
  /** Return the federation host with the given ID */
  federationHost?: Maybe<FederationHost>;
  /** Return a list of the federation hosts */
  federationHosts?: Maybe<Array<Maybe<FederationHost>>>;
  /** Return a device provision with the given ID, including detail of Okto Resources */
  fetchProvision?: Maybe<Provision>;
  oktoResourceLogs: Scalars["String"];
  /** Return the Org with the given ID */
  org?: Maybe<Org>;
  /** Return a list of all orgs */
  orgs?: Maybe<Array<Maybe<Org>>>;
  /** Return the baremetal / software values of a provision chart */
  provisionChart?: Maybe<ProvisionChart>;
  /** Return all versions of all provision Charts in the registry */
  provisionCharts?: Maybe<Array<Maybe<ChartKey>>>;
  /** Return all device provisions within a site */
  provisions?: Maybe<Array<Provision>>;
  serviceChain?: Maybe<ServiceChain>;
  serviceChains?: Maybe<Array<Maybe<ServiceChain>>>;
  settings?: Maybe<Settings>;
  /** Return the Site with the given ID */
  site?: Maybe<Site>;
  templateChart?: Maybe<TemplateChart>;
  /** Return the latest versions of all Template Charts in the registry */
  templateCharts?: Maybe<Array<Maybe<TemplateChart>>>;
  /** Return the User with the given ID, or if the ID is null, the currently logged in user */
  user?: Maybe<User>;
  /** Return all users (super admin only) */
  users?: Maybe<Array<Maybe<User>>>;
  version: Scalars["String"];
};

export type QueryAuditLogsArgs = {
  endTime?: InputMaybe<Scalars["String"]>;
  pagination?: InputMaybe<PaginationInput>;
  startTime?: InputMaybe<Scalars["String"]>;
};

export type QueryBlockChartArgs = {
  name: Scalars["String"];
  version?: InputMaybe<Scalars["String"]>;
};

export type QueryBlockChartsArgs = {
  category?: InputMaybe<Scalars["String"]>;
  vendors?: InputMaybe<Array<Scalars["ID"]>>;
};

export type QueryCloudResourceChartArgs = {
  name: Scalars["String"];
  version: Scalars["String"];
};

export type QueryConnectionsArgs = {
  entities?: InputMaybe<Array<Scalars["ID"]>>;
};

export type QueryDevicesArgs = {
  bbox?: InputMaybe<BBox>;
  id?: InputMaybe<Scalars["ID"]>;
  searchTerm?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Array<TagInput>>;
};

export type QueryEventLogsArgs = {
  endTime?: InputMaybe<Scalars["String"]>;
  pagination?: InputMaybe<PaginationInput>;
  startTime?: InputMaybe<Scalars["String"]>;
};

export type QueryFederationGuestArgs = {
  id: Scalars["ID"];
};

export type QueryFederationHostArgs = {
  id: Scalars["ID"];
};

export type QueryFetchProvisionArgs = {
  id: Scalars["ID"];
};

export type QueryOktoResourceLogsArgs = {
  id: Scalars["ID"];
};

export type QueryOrgArgs = {
  id: Scalars["ID"];
};

export type QueryProvisionChartArgs = {
  name: Scalars["String"];
  version: Scalars["String"];
};

export type QueryProvisionsArgs = {
  site: Scalars["ID"];
};

export type QueryServiceChainArgs = {
  id: Scalars["ID"];
};

export type QueryServiceChainsArgs = {
  org?: InputMaybe<Scalars["ID"]>;
  sites?: InputMaybe<Array<Scalars["ID"]>>;
};

export type QuerySiteArgs = {
  id: Scalars["ID"];
};

export type QueryTemplateChartArgs = {
  name: Scalars["String"];
  version?: InputMaybe<Scalars["String"]>;
};

export type QueryTemplateChartsArgs = {
  category?: InputMaybe<Scalars["String"]>;
  vendors?: InputMaybe<Array<Scalars["ID"]>>;
};

export type QueryUserArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type RecoveryLink = {
  __typename?: "RecoveryLink";
  expiresAt?: Maybe<Scalars["Time"]>;
  link?: Maybe<Scalars["String"]>;
};

export type RepoInput = {
  password: Scalars["String"];
  url: Scalars["String"];
  username: Scalars["String"];
};

export type RepoSettings = {
  __typename?: "RepoSettings";
  credentialsSet?: Maybe<Scalars["Boolean"]>;
  url?: Maybe<Scalars["String"]>;
};

export enum Role {
  Admin = "ADMIN",
  Mno = "MNO",
  Operator = "OPERATOR",
  Viewer = "VIEWER"
}

export type ServiceChain = AuditLogEntity & {
  __typename?: "ServiceChain";
  blocks?: Maybe<Array<Maybe<Block>>>;
  createdAt?: Maybe<Scalars["Time"]>;
  history?: Maybe<Array<ServiceChain>>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  org?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["String"]>;
  revision: Scalars["Int"];
  status?: Maybe<Status>;
};

export type ServiceChainHistoryArgs = {
  revision?: InputMaybe<Scalars["Int"]>;
};

export type ServiceParam = {
  __typename?: "ServiceParam";
  name: Scalars["String"];
  type: Scalars["String"];
};

export type SetFederationGuestStatusInput = {
  id: Scalars["ID"];
  status: FederationPartnerStatus;
};

export type Settings = {
  __typename?: "Settings";
  block?: Maybe<RepoSettings>;
  map?: Maybe<MapSettings>;
  provision?: Maybe<RepoSettings>;
  template?: Maybe<RepoSettings>;
};

export type Site = AuditLogEntity &
  SiteTreeItem & {
    __typename?: "Site";
    ancestors?: Maybe<Array<Maybe<Site>>>;
    description?: Maybe<Scalars["String"]>;
    /** fetch the devices of this site and all descendent sites */
    devices?: Maybe<DevicesResult>;
    displayName?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    org?: Maybe<Org>;
    sites?: Maybe<Array<Maybe<Site>>>;
  };

export type SiteInput = {
  ancestors?: InputMaybe<Array<Scalars["ID"]>>;
  description?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
};

export type SiteTreeItem = {
  description?: Maybe<Scalars["String"]>;
  devices?: Maybe<DevicesResult>;
  displayName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  sites?: Maybe<Array<Maybe<Site>>>;
};

export enum Status {
  Deleting = "DELETING",
  Error = "ERROR",
  InSync = "IN_SYNC",
  Pending = "PENDING",
  Progressing = "PROGRESSING",
  Unknown = "UNKNOWN"
}

export type Tag = {
  __typename?: "Tag";
  inc: Scalars["Boolean"];
  key: Scalars["String"];
};

export type TagInput = {
  inc: Scalars["Boolean"];
  key: Scalars["String"];
};

export type TelemetryUrl = {
  __typename?: "TelemetryUrl";
  displayName?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
};

export type TemplateChart = {
  __typename?: "TemplateChart";
  availableVersions?: Maybe<Array<Scalars["String"]>>;
  /** keywords field in Chart.yaml */
  categories?: Maybe<Array<Scalars["String"]>>;
  /** full contents of Chart.yaml */
  chartYaml?: Maybe<Scalars["String"]>;
  /** description field in Chart.yaml */
  description?: Maybe<Scalars["String"]>;
  /** annotations.displayName in Chart.yaml */
  displayName?: Maybe<Scalars["String"]>;
  /** Chart digest, used for client caching. Cannot fetch by ID! */
  id: Scalars["ID"];
  /** icon field in Chart.yaml */
  logoUrl?: Maybe<Scalars["String"]>;
  /** Unique chart name in Helm repo. name field in Chart.yaml */
  name: Scalars["String"];
  /** overrides.yaml, values.yaml if it doesn't exist */
  overridesYaml?: Maybe<Scalars["String"]>;
  /** values.yaml */
  valuesYaml?: Maybe<Scalars["String"]>;
  /** annotations.vendor field in Chart.yaml */
  vendor?: Maybe<Scalars["String"]>;
  /** version field in Chart.yaml */
  version: Scalars["String"];
};

export type UpdateCloudResourceInput = {
  blockVersion?: InputMaybe<Scalars["String"]>;
  deviceSite: Scalars["ID"];
  displayName: Scalars["String"];
  id: Scalars["ID"];
  position: LatLngInput;
  providerConfig?: InputMaybe<Array<CloudResourceConfigFieldInput>>;
};

export type User = AuditLogEntity & {
  __typename?: "User";
  email?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  loggedInOrg?: Maybe<Org>;
  loggedInRole?: Maybe<Role>;
  name?: Maybe<Scalars["String"]>;
  orgRoles?: Maybe<Array<Maybe<OrgRole>>>;
};

export type UserOrgRolesArgs = {
  org?: InputMaybe<Scalars["ID"]>;
};

export type VersionQueryVariables = Exact<{ [key: string]: never }>;

export type VersionQuery = { __typename?: "Query" } & Pick<Query, "version">;

export type DeleteChartMutationVariables = Exact<{
  name: Scalars["String"];
  version: Scalars["String"];
}>;

export type DeleteChartMutation = { __typename?: "Mutation" } & Pick<Mutation, "deleteBlockChart">;

export type AddDeviceMutationVariables = Exact<{
  dev: DeviceInput;
}>;

export type AddDeviceMutation = { __typename?: "Mutation" } & {
  device?: Maybe<{ __typename?: "Device" } & MatchesViewPartsFragment>;
};

export type FetchCloudResourceConfigQueryVariables = Exact<{
  name: Scalars["String"];
  version: Scalars["String"];
}>;

export type FetchCloudResourceConfigQuery = { __typename?: "Query" } & {
  cloudResourceChart?: Maybe<
    { __typename?: "CloudResourceChart" } & Pick<CloudResourceChart, "name" | "version"> & {
        originalConfig?: Maybe<
          Array<
            { __typename?: "CloudResourceConfigField" } & Pick<
              CloudResourceConfigField,
              "label" | "value" | "type" | "required" | "redacted"
            >
          >
        >;
      }
  >;
};

export type ListCloudResourceChartsQueryVariables = Exact<{ [key: string]: never }>;

export type ListCloudResourceChartsQuery = { __typename?: "Query" } & {
  cloudResourceCharts: Array<
    Maybe<
      { __typename?: "CloudResourceChartListing" } & Pick<
        CloudResourceChartListing,
        "displayName"
      > & { key: { __typename?: "ChartKey" } & Pick<ChartKey, "name" | "version"> }
    >
  >;
};

export type ProvisionCloudResourceMutationVariables = Exact<{
  input: CloudResourceInput;
}>;

export type ProvisionCloudResourceMutation = { __typename?: "Mutation" } & {
  cloudResource?: Maybe<{ __typename?: "Device" } & Pick<Device, "id">>;
};

export type UpdateCloudResourceDeviceMutationVariables = Exact<{
  input: UpdateCloudResourceInput;
}>;

export type UpdateCloudResourceDeviceMutation = { __typename?: "Mutation" } & {
  updateCloudResource?: Maybe<{ __typename?: "Device" } & Pick<Device, "id">>;
};

export type DeviceQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeviceQuery = { __typename?: "Query" } & {
  devices?: Maybe<
    { __typename?: "DevicesResult" } & {
      devices?: Maybe<Array<{ __typename?: "Device" } & MatchesViewPartsFragment>>;
    }
  >;
};

export type DeviceSpecsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeviceSpecsQuery = { __typename?: "Query" } & {
  devices?: Maybe<
    { __typename?: "DevicesResult" } & {
      devices?: Maybe<Array<{ __typename?: "Device" } & Pick<Device, "id" | "specs">>>;
    }
  >;
};

export type DeviceViewQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeviceViewQuery = { __typename?: "Query" } & {
  devices?: Maybe<
    { __typename?: "DevicesResult" } & {
      devices?: Maybe<
        Array<
          { __typename?: "Device" } & Pick<Device, "site" | "metadata"> & {
              progress?: Maybe<{ __typename?: "Progress" } & Pick<Progress, "step" | "goal">>;
              cloudResourceChart?: Maybe<
                { __typename?: "CloudResourceChart" } & Pick<CloudResourceChart, "name" | "version">
              >;
              telemetryUrl?: Maybe<
                Array<{ __typename?: "TelemetryUrl" } & Pick<TelemetryUrl, "displayName" | "url">>
              >;
            } & MatchesViewPartsFragment
        >
      >;
    }
  >;
};

export type DevicesQueryVariables = Exact<{
  tags?: Maybe<Array<TagInput> | TagInput>;
  bbox?: Maybe<BBox>;
}>;

export type DevicesQuery = { __typename?: "Query" } & {
  devices?: Maybe<
    { __typename?: "DevicesResult" } & {
      devices?: Maybe<Array<{ __typename?: "Device" } & MatchesViewPartsFragment>>;
    }
  >;
};

export type EditDeviceMutationVariables = Exact<{
  id: Scalars["ID"];
  device: EditDeviceInput;
}>;

export type EditDeviceMutation = { __typename?: "Mutation" } & {
  editDevice?: Maybe<{ __typename?: "Device" } & MatchesViewPartsFragment>;
};

export type EditableDeviceQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type EditableDeviceQuery = { __typename?: "Query" } & {
  devices?: Maybe<
    { __typename?: "DevicesResult" } & {
      devices?: Maybe<
        Array<
          { __typename?: "Device" } & Pick<Device, "site"> & {
              cloudResourceChart?: Maybe<
                { __typename?: "CloudResourceChart" } & Pick<
                  CloudResourceChart,
                  "name" | "version"
                > & {
                    latestConfig?: Maybe<
                      Array<
                        { __typename?: "CloudResourceConfigField" } & Pick<
                          CloudResourceConfigField,
                          "label" | "value" | "type" | "required" | "redacted"
                        >
                      >
                    >;
                  }
              >;
            } & MatchesViewPartsFragment
        >
      >;
    }
  >;
};

export type MatchesViewPartsFragment = { __typename?: "Device" } & Pick<
  Device,
  "id" | "displayName" | "tags" | "status"
> & {
    position?: Maybe<{ __typename?: "LatLng" } & Pick<LatLng, "lat" | "lng">>;
    connections?: Maybe<
      Array<
        Maybe<
          { __typename?: "Connection" } & Pick<
            Connection,
            "id" | "category" | "kind" | "name" | "source"
          >
        >
      >
    >;
  };

export type FetchProvisionEndpointsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchProvisionEndpointsQuery = { __typename?: "Query" } & {
  fetchProvision?: Maybe<
    { __typename?: "Provision" } & Pick<Provision, "id"> & {
        logEndpoints?: Maybe<
          Array<Maybe<{ __typename?: "LogEndpoint" } & Pick<LogEndpoint, "id" | "hash" | "name">>>
        >;
      }
  >;
};

export type FetchProvisionLogsQueryVariables = Exact<{
  id: Scalars["ID"];
  endpointId: Scalars["ID"];
  hash: Scalars["String"];
  sourceId: Scalars["String"];
}>;

export type FetchProvisionLogsQuery = { __typename?: "Query" } & {
  fetchProvision?: Maybe<
    { __typename?: "Provision" } & Pick<Provision, "id"> & {
        logEndpoint?: Maybe<
          { __typename?: "LogEndpoint" } & {
            source?: Maybe<
              { __typename?: "LogSource" } & {
                logs?: Maybe<Array<Maybe<{ __typename?: "LogEntry" } & Pick<LogEntry, "log">>>>;
              }
            >;
          }
        >;
      }
  >;
};

export type FetchProvisionSourcesQueryVariables = Exact<{
  id: Scalars["ID"];
  endpointId: Scalars["ID"];
  hash: Scalars["String"];
}>;

export type FetchProvisionSourcesQuery = { __typename?: "Query" } & {
  fetchProvision?: Maybe<
    { __typename?: "Provision" } & Pick<Provision, "id"> & {
        logEndpoint?: Maybe<
          { __typename?: "LogEndpoint" } & {
            sources?: Maybe<
              Array<Maybe<{ __typename?: "LogSource" } & Pick<LogSource, "id" | "name">>>
            >;
          }
        >;
      }
  >;
};

export type FetchProvisionsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchProvisionsQuery = { __typename?: "Query" } & {
  provisions?: Maybe<
    Array<
      { __typename?: "Provision" } & {
        phases?: Maybe<
          Array<
            Maybe<
              { __typename?: "ProvisionPhase" } & Pick<ProvisionPhase, "name"> & {
                  progress?: Maybe<{ __typename?: "Progress" } & Pick<Progress, "step" | "goal">>;
                }
            >
          >
        >;
      } & ProvisionFieldsFragment
    >
  >;
};

export type ProfileDetailsQueryVariables = Exact<{
  name: Scalars["String"];
  version: Scalars["String"];
}>;

export type ProfileDetailsQuery = { __typename?: "Query" } & {
  provisionChart?: Maybe<
    { __typename?: "ProvisionChart" } & Pick<ProvisionChart, "name" | "version"> & {
        phases?: Maybe<
          Array<
            Maybe<
              { __typename?: "ProvisionPhase" } & Pick<
                ProvisionPhase,
                "name" | "values" | "enabled"
              >
            >
          >
        >;
      }
  >;
};

export type ProfileSelectQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileSelectQuery = { __typename?: "Query" } & {
  provisionCharts?: Maybe<
    Array<Maybe<{ __typename?: "ChartKey" } & Pick<ChartKey, "name" | "version">>>
  >;
};

export type ProvisionDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProvisionDetailsQuery = { __typename?: "Query" } & {
  fetchProvision?: Maybe<
    { __typename?: "Provision" } & Pick<Provision, "lastUpdated" | "inventory"> & {
        chart?: Maybe<{ __typename?: "ProvisionChart" } & Pick<ProvisionChart, "name" | "version">>;
        siteTreeItem?: Maybe<
          | ({ __typename?: "Org" } & Pick<Org, "id" | "displayName">)
          | ({ __typename?: "Site" } & Pick<Site, "id" | "displayName">)
        >;
        phases?: Maybe<
          Array<
            Maybe<
              { __typename?: "ProvisionPhase" } & Pick<ProvisionPhase, "name" | "values"> & {
                  progress?: Maybe<{ __typename?: "Progress" } & Pick<Progress, "step" | "goal">>;
                  resources?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "OktoResource" } & Pick<
                          OktoResource,
                          "id" | "name" | "kind" | "status" | "manifest"
                        >
                      >
                    >
                  >;
                }
            >
          >
        >;
      } & ProvisionFieldsFragment
  >;
};

export type ProvisionDeviceMutationVariables = Exact<{
  input: ProvisionInput;
}>;

export type ProvisionDeviceMutation = { __typename?: "Mutation" } & {
  provision?: Maybe<{ __typename?: "Device" } & Pick<Device, "id">>;
};

export type ProvisionFieldsFragment = { __typename?: "Provision" } & Pick<
  Provision,
  "id" | "displayName" | "status"
>;

export type RemoveDeviceMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveDeviceMutation = { __typename?: "Mutation" } & Pick<Mutation, "removeDevice">;

export type GoogleMapsApiTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GoogleMapsApiTokenQuery = { __typename?: "Query" } & {
  apiTokens?: Maybe<{ __typename?: "ApiTokens" } & Pick<ApiTokens, "id" | "googleMaps">>;
};

export type MapboxApiTokenQueryVariables = Exact<{ [key: string]: never }>;

export type MapboxApiTokenQuery = { __typename?: "Query" } & {
  apiTokens?: Maybe<{ __typename?: "ApiTokens" } & Pick<ApiTokens, "id" | "mapbox">>;
};

export type SiteTreeItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SiteTreeItemQuery = { __typename?: "Query" } & {
  site?: Maybe<
    { __typename?: "Site" } & Pick<Site, "id" | "displayName"> & {
        ancestors?: Maybe<Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id">>>>;
        sites?: Maybe<Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id">>>>;
      }
  >;
};

export type SiteTreeOrgQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SiteTreeOrgQuery = { __typename?: "Query" } & {
  org?: Maybe<
    { __typename?: "Org" } & Pick<Org, "id" | "displayName"> & {
        sites?: Maybe<Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id">>>>;
      }
  >;
};

export type AddOrgMutationVariables = Exact<{
  org: OrgInput;
}>;

export type AddOrgMutation = { __typename?: "Mutation" } & {
  createOrg?: Maybe<{ __typename?: "Org" } & Pick<Org, "id" | "displayName" | "description">>;
};

export type AddSiteMutationVariables = Exact<{
  site: SiteInput;
}>;

export type AddSiteMutation = { __typename?: "Mutation" } & {
  createSite?: Maybe<
    { __typename?: "Site" } & Pick<Site, "id" | "displayName" | "description"> & {
        ancestors?: Maybe<Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id" | "displayName">>>>;
      }
  >;
};

export type EditOrgMutationVariables = Exact<{
  id: Scalars["ID"];
  org: OrgInput;
}>;

export type EditOrgMutation = { __typename?: "Mutation" } & {
  editOrg?: Maybe<{ __typename?: "Org" } & Pick<Org, "id" | "displayName" | "description">>;
};

export type EditSiteMutationVariables = Exact<{
  id: Scalars["ID"];
  site: SiteInput;
}>;

export type EditSiteMutation = { __typename?: "Mutation" } & {
  editSite?: Maybe<
    { __typename?: "Site" } & Pick<Site, "id" | "displayName" | "description"> & {
        ancestors?: Maybe<Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id" | "displayName">>>>;
      }
  >;
};

export type EditSiteDisplayNameMutationVariables = Exact<{
  id: Scalars["ID"];
  site: SiteInput;
}>;

export type EditSiteDisplayNameMutation = { __typename?: "Mutation" } & {
  editSite?: Maybe<{ __typename?: "Site" } & Pick<Site, "id" | "displayName">>;
};

export type FetchOrgQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchOrgQuery = { __typename?: "Query" } & {
  org?: Maybe<
    { __typename?: "Org" } & Pick<Org, "id" | "displayName" | "description"> & {
        sites?: Maybe<Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id">>>>;
        devices?: Maybe<
          { __typename?: "DevicesResult" } & {
            devices?: Maybe<
              Array<
                { __typename?: "Device" } & Pick<
                  Device,
                  "id" | "displayName" | "status" | "tags"
                > & { position?: Maybe<{ __typename?: "LatLng" } & Pick<LatLng, "lat" | "lng">> }
              >
            >;
          }
        >;
      }
  >;
};

export type FetchOrgDevicesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchOrgDevicesQuery = { __typename?: "Query" } & {
  org?: Maybe<
    { __typename?: "Org" } & Pick<Org, "id"> & {
        devices?: Maybe<
          { __typename?: "DevicesResult" } & {
            devices?: Maybe<
              Array<
                { __typename?: "Device" } & Pick<
                  Device,
                  "id" | "displayName" | "tags" | "site" | "status"
                > & {
                    position?: Maybe<{ __typename?: "LatLng" } & Pick<LatLng, "lat" | "lng">>;
                    cloudResourceChart?: Maybe<
                      { __typename?: "CloudResourceChart" } & Pick<
                        CloudResourceChart,
                        "name" | "version"
                      >
                    >;
                  }
              >
            >;
          }
        >;
      }
  >;
};

export type FetchOrgInformationQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchOrgInformationQuery = { __typename?: "Query" } & {
  org?: Maybe<
    { __typename?: "Org" } & Pick<Org, "id" | "displayName"> & {
        devices?: Maybe<
          { __typename?: "DevicesResult" } & {
            devices?: Maybe<Array<{ __typename?: "Device" } & Pick<Device, "id">>>;
          }
        >;
        sites?: Maybe<Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id">>>>;
      }
  >;
};

export type FetchOrgSitesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchOrgSitesQuery = { __typename?: "Query" } & {
  org?: Maybe<
    { __typename?: "Org" } & Pick<Org, "id"> & {
        sites?: Maybe<Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id">>>>;
      }
  >;
};

export type FetchSiteQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchSiteQuery = { __typename?: "Query" } & {
  site?: Maybe<
    { __typename?: "Site" } & Pick<Site, "id" | "displayName" | "description"> & {
        ancestors?: Maybe<Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id" | "displayName">>>>;
        sites?: Maybe<Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id">>>>;
        devices?: Maybe<
          { __typename?: "DevicesResult" } & {
            devices?: Maybe<
              Array<
                { __typename?: "Device" } & Pick<
                  Device,
                  "id" | "displayName" | "status" | "tags"
                > & { position?: Maybe<{ __typename?: "LatLng" } & Pick<LatLng, "lat" | "lng">> }
              >
            >;
          }
        >;
      }
  >;
};

export type FetchSiteDevicesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchSiteDevicesQuery = { __typename?: "Query" } & {
  site?: Maybe<
    { __typename?: "Site" } & Pick<Site, "id"> & {
        ancestors?: Maybe<Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id">>>>;
        devices?: Maybe<
          { __typename?: "DevicesResult" } & {
            devices?: Maybe<
              Array<
                { __typename?: "Device" } & Pick<
                  Device,
                  "id" | "displayName" | "tags" | "site" | "status"
                > & {
                    position?: Maybe<{ __typename?: "LatLng" } & Pick<LatLng, "lat" | "lng">>;
                    cloudResourceChart?: Maybe<
                      { __typename?: "CloudResourceChart" } & Pick<
                        CloudResourceChart,
                        "name" | "version"
                      >
                    >;
                  }
              >
            >;
          }
        >;
      }
  >;
};

export type FetchSiteInformationQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchSiteInformationQuery = { __typename?: "Query" } & {
  site?: Maybe<
    { __typename?: "Site" } & Pick<Site, "id" | "displayName"> & {
        ancestors?: Maybe<Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id">>>>;
        org?: Maybe<{ __typename?: "Org" } & Pick<Org, "id">>;
        devices?: Maybe<
          { __typename?: "DevicesResult" } & {
            devices?: Maybe<Array<{ __typename?: "Device" } & Pick<Device, "id">>>;
          }
        >;
        sites?: Maybe<Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id">>>>;
      }
  >;
};

export type OrgTitleQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OrgTitleQuery = { __typename?: "Query" } & {
  org?: Maybe<{ __typename?: "Org" } & Pick<Org, "id" | "displayName">>;
};

export type RemoveOrgMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveOrgMutation = { __typename?: "Mutation" } & Pick<Mutation, "removeOrg">;

export type RemoveSiteMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveSiteMutation = { __typename?: "Mutation" } & Pick<Mutation, "removeSite">;

export type ServiceChainsQueryVariables = Exact<{
  org?: Maybe<Scalars["ID"]>;
  site: Scalars["ID"];
}>;

export type ServiceChainsQuery = { __typename?: "Query" } & {
  serviceChains?: Maybe<
    Array<
      Maybe<
        { __typename?: "ServiceChain" } & Pick<
          ServiceChain,
          "id" | "revision" | "name" | "status" | "createdAt"
        >
      >
    >
  >;
};

export type SiteTitleQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SiteTitleQuery = { __typename?: "Query" } & {
  site?: Maybe<
    { __typename?: "Site" } & Pick<Site, "id" | "displayName"> & {
        org?: Maybe<{ __typename?: "Org" } & Pick<Org, "id" | "displayName">>;
        ancestors?: Maybe<Array<Maybe<{ __typename?: "Site" } & Pick<Site, "id" | "displayName">>>>;
      }
  >;
};

export type BlockInfoFragment = { __typename?: "Block" } & Pick<
  Block,
  "id" | "chainRevision" | "displayName" | "status" | "values"
> & {
    connections?: Maybe<
      Array<
        Maybe<
          { __typename?: "Connection" } & Pick<
            Connection,
            "id" | "name" | "kind" | "category" | "source"
          >
        >
      >
    >;
    chart?: Maybe<
      { __typename?: "BlockChart" } & Pick<
        BlockChart,
        "id" | "name" | "description" | "logoUrl" | "vendor" | "version"
      >
    >;
    resources?: Maybe<
      Array<
        Maybe<
          { __typename?: "OktoResource" } & Pick<
            OktoResource,
            "id" | "name" | "kind" | "status" | "manifest"
          >
        >
      >
    >;
    controls?: Maybe<
      Array<
        Maybe<
          { __typename?: "BlockControl" } & Pick<
            BlockControl,
            "id" | "displayName" | "value" | "options"
          >
        >
      >
    >;
  };

export type FetchEndpointSourcesQueryVariables = Exact<{
  id: Scalars["ID"];
  endpointId: Scalars["ID"];
  hash: Scalars["String"];
}>;

export type FetchEndpointSourcesQuery = { __typename?: "Query" } & {
  serviceChain?: Maybe<
    { __typename?: "ServiceChain" } & {
      blocks?: Maybe<
        Array<
          Maybe<
            { __typename?: "Block" } & Pick<Block, "id" | "chainRevision"> & {
                logEndpoint?: Maybe<
                  { __typename?: "LogEndpoint" } & {
                    sources?: Maybe<
                      Array<Maybe<{ __typename?: "LogSource" } & Pick<LogSource, "id" | "name">>>
                    >;
                  }
                >;
              }
          >
        >
      >;
    }
  >;
};

export type FetchInstanceEndpointsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchInstanceEndpointsQuery = { __typename?: "Query" } & {
  serviceChain?: Maybe<
    { __typename?: "ServiceChain" } & {
      blocks?: Maybe<
        Array<
          Maybe<
            { __typename?: "Block" } & Pick<Block, "id" | "chainRevision"> & {
                logEndpoints?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: "LogEndpoint" } & Pick<LogEndpoint, "id" | "hash" | "name">
                    >
                  >
                >;
              }
          >
        >
      >;
    }
  >;
};

export type FetchSourceLogsQueryVariables = Exact<{
  id: Scalars["ID"];
  endpointId: Scalars["ID"];
  hash: Scalars["String"];
  sourceId: Scalars["String"];
  logFilterSimpleQuery?: Maybe<Scalars["String"]>;
  size: Scalars["Int"];
  offset: Scalars["Int"];
}>;

export type FetchSourceLogsQuery = { __typename?: "Query" } & {
  serviceChain?: Maybe<
    { __typename?: "ServiceChain" } & {
      blocks?: Maybe<
        Array<
          Maybe<
            { __typename?: "Block" } & Pick<Block, "id" | "chainRevision"> & {
                logEndpoint?: Maybe<
                  { __typename?: "LogEndpoint" } & {
                    source?: Maybe<
                      { __typename?: "LogSource" } & {
                        logs?: Maybe<
                          Array<
                            Maybe<{ __typename?: "LogEntry" } & Pick<LogEntry, "log" | "metadata">>
                          >
                        >;
                      }
                    >;
                  }
                >;
              }
          >
        >
      >;
    }
  >;
};

export type FetchInstanceBlocksQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchInstanceBlocksQuery = { __typename?: "Query" } & {
  serviceChain?: Maybe<
    { __typename?: "ServiceChain" } & Pick<ServiceChain, "id" | "revision"> & {
        blocks?: Maybe<Array<Maybe<{ __typename?: "Block" } & BlockInfoFragment>>>;
      }
  >;
};

export type FetchInstanceInfoQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchInstanceInfoQuery = { __typename?: "Query" } & {
  serviceChain?: Maybe<
    { __typename?: "ServiceChain" } & Pick<
      ServiceChain,
      "id" | "name" | "revision" | "createdAt"
    > & {
        history?: Maybe<
          Array<{ __typename?: "ServiceChain" } & Pick<ServiceChain, "id" | "revision">>
        >;
      }
  >;
};

export type FetchRevisionQueryVariables = Exact<{
  id: Scalars["ID"];
  revision?: Maybe<Scalars["Int"]>;
}>;

export type FetchRevisionQuery = { __typename?: "Query" } & {
  serviceChain?: Maybe<
    { __typename?: "ServiceChain" } & Pick<ServiceChain, "id" | "revision"> & {
        history?: Maybe<
          Array<
            { __typename?: "ServiceChain" } & Pick<ServiceChain, "id" | "revision"> & {
                blocks?: Maybe<Array<Maybe<{ __typename?: "Block" } & BlockInfoFragment>>>;
              }
          >
        >;
      }
  >;
};

export type InstanceInfoFragment = { __typename?: "ServiceChain" } & Pick<
  ServiceChain,
  "id" | "name" | "createdAt"
> & {
    history?: Maybe<Array<{ __typename?: "ServiceChain" } & Pick<ServiceChain, "id" | "revision">>>;
  };

export type InstancesQueryVariables = Exact<{ [key: string]: never }>;

export type InstancesQuery = { __typename?: "Query" } & {
  serviceChains?: Maybe<
    Array<
      Maybe<
        { __typename?: "ServiceChain" } & Pick<
          ServiceChain,
          "id" | "revision" | "name" | "status" | "createdAt"
        >
      >
    >
  >;
};

export type OktoResourceLogsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OktoResourceLogsQuery = { __typename?: "Query" } & Pick<Query, "oktoResourceLogs">;

export type RemoveServiceChainMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveServiceChainMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "removeServiceChain"
>;

export type RollbackRevisionMutationVariables = Exact<{
  id: Scalars["ID"];
  revision: Scalars["Int"];
}>;

export type RollbackRevisionMutation = { __typename?: "Mutation" } & {
  rollbackServiceChain?: Maybe<
    { __typename?: "ServiceChain" } & Pick<ServiceChain, "id" | "revision" | "createdAt"> & {
        history?: Maybe<
          Array<{ __typename?: "ServiceChain" } & Pick<ServiceChain, "id" | "revision">>
        >;
        blocks?: Maybe<Array<Maybe<{ __typename?: "Block" } & BlockInfoFragment>>>;
      }
  >;
};

export type ServiceChainInstanceFragment = { __typename?: "ServiceChain" } & Pick<
  ServiceChain,
  "id" | "revision" | "name" | "status" | "owner" | "createdAt"
> & {
    blocks?: Maybe<
      Array<
        Maybe<
          { __typename?: "Block" } & Pick<
            Block,
            "id" | "chainRevision" | "displayName" | "status"
          > & {
              connections?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "Connection" } & Pick<Connection, "name" | "category" | "source">
                  >
                >
              >;
              resources?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "OktoResource" } & Pick<
                      OktoResource,
                      "id" | "name" | "kind" | "status"
                    >
                  >
                >
              >;
            }
        >
      >
    >;
  };

export type ServiceChainStatusFragment = { __typename?: "ServiceChain" } & Pick<
  ServiceChain,
  "id" | "status"
>;

export type UpdateControlMutationVariables = Exact<{
  id: Scalars["ID"];
  value: Scalars["String"];
}>;

export type UpdateControlMutation = { __typename?: "Mutation" } & {
  setControlValue?: Maybe<
    { __typename?: "BlockControl" } & Pick<BlockControl, "id" | "value" | "displayName" | "options">
  >;
};

export type FetchPanelLogsQueryVariables = Exact<{
  startTime?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["String"]>;
  pageSize: Scalars["Int"];
}>;

export type FetchPanelLogsQuery = { __typename?: "Query" } & {
  auditLogs?: Maybe<
    { __typename?: "AuditLogs" } & {
      edges: Array<
        Maybe<
          { __typename?: "AuditLogEntryEdge" } & {
            node?: Maybe<{ __typename?: "AuditLogEntry" } & AuditLogNodeFragment>;
          }
        >
      >;
    }
  >;
};

export type FetchTemplateChartQueryVariables = Exact<{
  name: Scalars["String"];
  version?: Maybe<Scalars["String"]>;
}>;

export type FetchTemplateChartQuery = { __typename?: "Query" } & {
  templateChart?: Maybe<
    { __typename?: "TemplateChart" } & Pick<
      TemplateChart,
      | "id"
      | "chartYaml"
      | "valuesYaml"
      | "overridesYaml"
      | "displayName"
      | "vendor"
      | "description"
      | "logoUrl"
      | "categories"
      | "availableVersions"
    >
  >;
};

export type PublishBlockChartMutationVariables = Exact<{
  input: PublishBlockChartInput;
}>;

export type PublishBlockChartMutation = { __typename?: "Mutation" } & {
  publishBlockChart?: Maybe<
    { __typename?: "BlockChart" } & Pick<
      BlockChart,
      | "id"
      | "name"
      | "version"
      | "categories"
      | "description"
      | "displayName"
      | "vendor"
      | "logoUrl"
      | "availableVersions"
    >
  >;
};

export type TemplateChartInformationQueryVariables = Exact<{
  name: Scalars["String"];
  version?: Maybe<Scalars["String"]>;
}>;

export type TemplateChartInformationQuery = { __typename?: "Query" } & {
  templateChart?: Maybe<
    { __typename?: "TemplateChart" } & Pick<
      TemplateChart,
      | "id"
      | "displayName"
      | "name"
      | "version"
      | "categories"
      | "description"
      | "chartYaml"
      | "valuesYaml"
      | "overridesYaml"
      | "vendor"
      | "logoUrl"
      | "availableVersions"
    >
  >;
};

export type TemplatesQueryVariables = Exact<{
  category?: Maybe<Scalars["String"]>;
  vendors?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type TemplatesQuery = { __typename?: "Query" } & {
  settings?: Maybe<
    { __typename?: "Settings" } & {
      template?: Maybe<{ __typename?: "RepoSettings" } & Pick<RepoSettings, "url">>;
    }
  >;
  templateCharts?: Maybe<
    Array<
      Maybe<
        { __typename?: "TemplateChart" } & Pick<
          TemplateChart,
          | "id"
          | "name"
          | "version"
          | "categories"
          | "description"
          | "displayName"
          | "vendor"
          | "logoUrl"
        >
      >
    >
  >;
};

export type AuditLogNodeFragment = { __typename?: "AuditLogEntry" } & Pick<
  AuditLogEntry,
  "time" | "message" | "type" | "reason" | "entityType"
> & {
    org?: Maybe<{ __typename?: "Org" } & Pick<Org, "id" | "displayName">>;
    user?: Maybe<{ __typename?: "User" } & Pick<User, "id" | "email">>;
    entity?: Maybe<
      | ({ __typename?: "BlockChart" } & Pick<BlockChart, "id"> & {
            blockChartName: BlockChart["name"];
          })
      | ({ __typename?: "Device" } & Pick<Device, "id"> & { name: Device["displayName"] })
      | ({ __typename?: "FederationGuest" } & Pick<FederationGuest, "id"> & {
            name: FederationGuest["federationName"];
          })
      | ({ __typename?: "FederationHost" } & Pick<FederationHost, "id"> & {
            name: FederationHost["federationName"];
          })
      | ({ __typename?: "GenericAuditLogEntity" } & Pick<GenericAuditLogEntity, "name" | "id">)
      | ({ __typename?: "Org" } & Pick<Org, "id"> & { name: Org["displayName"] })
      | ({ __typename?: "Provision" } & Pick<Provision, "status" | "id"> & {
            name: Provision["displayName"];
          })
      | ({ __typename?: "ServiceChain" } & Pick<ServiceChain, "name" | "revision" | "id">)
      | ({ __typename?: "Site" } & Pick<Site, "id"> & { name: Site["displayName"] })
      | ({ __typename?: "User" } & Pick<User, "id"> & { name: User["email"] })
    >;
  };

export type FetchAuditLogsQueryVariables = Exact<{
  startTime?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["String"]>;
  pageSize: Scalars["Int"];
  beforeCursor?: Maybe<Scalars["String"]>;
  afterCursor?: Maybe<Scalars["String"]>;
}>;

export type FetchAuditLogsQuery = { __typename?: "Query" } & {
  auditLogs?: Maybe<
    { __typename?: "AuditLogs" } & Pick<AuditLogs, "totalCount"> & {
        edges: Array<
          Maybe<
            { __typename?: "AuditLogEntryEdge" } & Pick<AuditLogEntryEdge, "cursor"> & {
                node?: Maybe<{ __typename?: "AuditLogEntry" } & AuditLogNodeFragment>;
              }
          >
        >;
        pageInfo?: Maybe<
          { __typename?: "PageInfo" } & Pick<
            PageInfo,
            "hasNextPage" | "hasPreviousPage" | "startCursor" | "endCursor"
          >
        >;
      }
  >;
};

export type FetchEventLogsQueryVariables = Exact<{
  startTime?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["String"]>;
  pageSize: Scalars["Int"];
  beforeCursor?: Maybe<Scalars["String"]>;
  afterCursor?: Maybe<Scalars["String"]>;
}>;

export type FetchEventLogsQuery = { __typename?: "Query" } & {
  eventLogs?: Maybe<
    { __typename?: "EventLogs" } & Pick<EventLogs, "totalCount"> & {
        edges: Array<
          Maybe<
            { __typename?: "EventLogEntryEdge" } & Pick<EventLogEntryEdge, "cursor"> & {
                node?: Maybe<
                  { __typename?: "EventLogEntry" } & Pick<
                    EventLogEntry,
                    "time" | "message" | "type" | "reason"
                  > & {
                      resource?: Maybe<
                        { __typename?: "OktoResource" } & Pick<
                          OktoResource,
                          "id" | "name" | "kind" | "status" | "namespace" | "manifest"
                        >
                      >;
                    }
                >;
              }
          >
        >;
        pageInfo?: Maybe<
          { __typename?: "PageInfo" } & Pick<
            PageInfo,
            "hasNextPage" | "hasPreviousPage" | "startCursor" | "endCursor"
          >
        >;
      }
  >;
};

export type DeployServiceChainMutationVariables = Exact<{
  name: Scalars["String"];
  blocks?: Maybe<Array<BlockInput> | BlockInput>;
}>;

export type DeployServiceChainMutation = { __typename?: "Mutation" } & {
  deployServiceChain?: Maybe<{ __typename?: "ServiceChain" } & ServiceChainInstanceFragment>;
};

export type FetchBlockChartQueryVariables = Exact<{
  name: Scalars["String"];
  version?: Maybe<Scalars["String"]>;
}>;

export type FetchBlockChartQuery = { __typename?: "Query" } & {
  blockChart?: Maybe<
    { __typename?: "BlockChart" } & Pick<BlockChart, "id" | "chartYaml" | "overridesYaml">
  >;
};

export type FetchBlockInformationQueryVariables = Exact<{
  name: Scalars["String"];
  version?: Maybe<Scalars["String"]>;
}>;

export type FetchBlockInformationQuery = { __typename?: "Query" } & {
  blockChart?: Maybe<
    { __typename?: "BlockChart" } & Pick<
      BlockChart,
      | "id"
      | "displayName"
      | "name"
      | "version"
      | "categories"
      | "description"
      | "chartYaml"
      | "overridesYaml"
      | "vendor"
      | "logoUrl"
      | "availableVersions"
      | "valuesSchema"
    >
  >;
};

export type FetchChartYamlQueryVariables = Exact<{
  name: Scalars["String"];
  version?: Maybe<Scalars["String"]>;
}>;

export type FetchChartYamlQuery = { __typename?: "Query" } & {
  blockChart?: Maybe<{ __typename?: "BlockChart" } & Pick<BlockChart, "id" | "chartYaml">>;
};

export type PopulateMarketplaceQueryVariables = Exact<{
  category?: Maybe<Scalars["String"]>;
  vendors?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type PopulateMarketplaceQuery = { __typename?: "Query" } & {
  settings?: Maybe<
    { __typename?: "Settings" } & {
      block?: Maybe<{ __typename?: "RepoSettings" } & Pick<RepoSettings, "url">>;
    }
  >;
  blockCharts?: Maybe<
    Array<
      Maybe<
        { __typename?: "BlockChart" } & Pick<
          BlockChart,
          | "id"
          | "name"
          | "version"
          | "categories"
          | "description"
          | "displayName"
          | "vendor"
          | "logoUrl"
          | "availableVersions"
        >
      >
    >
  >;
};

export type ServiceDesignerFormParamsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ServiceDesignerFormParamsQuery = { __typename?: "Query" } & {
  serviceChain?: Maybe<
    { __typename?: "ServiceChain" } & Pick<ServiceChain, "id" | "revision" | "name"> & {
        blocks?: Maybe<
          Array<
            Maybe<
              { __typename?: "Block" } & Pick<
                Block,
                "id" | "chainRevision" | "displayName" | "values" | "selectedVersion"
              > & {
                  chart?: Maybe<
                    { __typename?: "BlockChart" } & Pick<
                      BlockChart,
                      "id" | "version" | "name" | "availableVersions"
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type ServiceDesignerPreviousValuesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ServiceDesignerPreviousValuesQuery = { __typename?: "Query" } & {
  serviceChain?: Maybe<
    { __typename?: "ServiceChain" } & {
      blocks?: Maybe<
        Array<Maybe<{ __typename?: "Block" } & Pick<Block, "id" | "displayName" | "values">>>
      >;
    }
  >;
};

export type UpdateServiceChainMutationVariables = Exact<{
  id: Scalars["ID"];
  name: Scalars["String"];
  blocks?: Maybe<Array<BlockInput> | BlockInput>;
}>;

export type UpdateServiceChainMutation = { __typename?: "Mutation" } & {
  updateServiceChain?: Maybe<{ __typename?: "ServiceChain" } & ServiceChainInstanceFragment>;
};

export type FetchCompletionsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchCompletionsQuery = { __typename?: "Query" } & Pick<Query, "customCompletions">;

export type SetCompletionsMutationVariables = Exact<{
  json: Scalars["String"];
}>;

export type SetCompletionsMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "setCustomCompletions"
>;

export type BlockRepoUrlQueryVariables = Exact<{ [key: string]: never }>;

export type BlockRepoUrlQuery = { __typename?: "Query" } & {
  settings?: Maybe<
    { __typename?: "Settings" } & {
      block?: Maybe<{ __typename?: "RepoSettings" } & Pick<RepoSettings, "url" | "credentialsSet">>;
    }
  >;
};

export type ProvisionRepoUrlQueryVariables = Exact<{ [key: string]: never }>;

export type ProvisionRepoUrlQuery = { __typename?: "Query" } & {
  settings?: Maybe<
    { __typename?: "Settings" } & {
      provision?: Maybe<
        { __typename?: "RepoSettings" } & Pick<RepoSettings, "url" | "credentialsSet">
      >;
    }
  >;
};

export type SetBlockRepoMutationVariables = Exact<{
  repo: RepoInput;
}>;

export type SetBlockRepoMutation = { __typename?: "Mutation" } & Pick<Mutation, "setBlockRepo">;

export type SetProvisionRepoMutationVariables = Exact<{
  repo: RepoInput;
}>;

export type SetProvisionRepoMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "setProvisionRepo"
>;

export type SetTemplateRepoMutationVariables = Exact<{
  repo: RepoInput;
}>;

export type SetTemplateRepoMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "setTemplateRepo"
>;

export type TemplateRepoUrlQueryVariables = Exact<{ [key: string]: never }>;

export type TemplateRepoUrlQuery = { __typename?: "Query" } & {
  settings?: Maybe<
    { __typename?: "Settings" } & {
      template?: Maybe<
        { __typename?: "RepoSettings" } & Pick<RepoSettings, "url" | "credentialsSet">
      >;
    }
  >;
};

export type DeviceSearchQueryVariables = Exact<{
  searchTerm?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<TagInput> | TagInput>;
}>;

export type DeviceSearchQuery = { __typename?: "Query" } & {
  devices?: Maybe<
    { __typename?: "DevicesResult" } & {
      devices?: Maybe<
        Array<
          { __typename?: "Device" } & Pick<Device, "id" | "displayName"> & {
              position?: Maybe<{ __typename?: "LatLng" } & Pick<LatLng, "lat" | "lng">>;
            }
        >
      >;
    }
  >;
};

export type AddFederationGuestMutationVariables = Exact<{
  guest: FederationGuestInput;
}>;

export type AddFederationGuestMutation = { __typename?: "Mutation" } & {
  createFederationGuest?: Maybe<
    { __typename?: "NewFederationGuest" } & {
      federationGuest: { __typename?: "FederationGuest" } & Pick<
        FederationGuest,
        "id" | "federationName" | "operatorName" | "countryCode" | "mcc" | "status"
      >;
      clientCredentials: { __typename?: "ClientCredentials" } & Pick<
        ClientCredentials,
        "clientId" | "clientSecret"
      >;
    }
  >;
};

export type AddFederationHostMutationVariables = Exact<{
  host: FederationHostInput;
}>;

export type AddFederationHostMutation = { __typename?: "Mutation" } & {
  createFederationHost?: Maybe<
    { __typename?: "FederationHost" } & Pick<
      FederationHost,
      | "id"
      | "federationName"
      | "operatorName"
      | "countryCode"
      | "mcc"
      | "mncs"
      | "hostUrl"
      | "siteId"
      | "status"
    >
  >;
};

export type EditFederationMutationVariables = Exact<{
  id: Scalars["ID"];
  fed: FederationInput;
}>;

export type EditFederationMutation = { __typename?: "Mutation" } & {
  editFederation?: Maybe<
    { __typename?: "Org" } & Pick<Org, "id"> & {
        federation?: Maybe<
          { __typename?: "Federation" } & Pick<
            Federation,
            "operatorName" | "countryCode" | "mcc" | "mncs" | "federationUrl"
          >
        >;
      }
  >;
};

export type EditFederationGuestMutationVariables = Exact<{
  info: EditFederationGuestInput;
}>;

export type EditFederationGuestMutation = { __typename?: "Mutation" } & {
  editFederationGuest?: Maybe<
    { __typename?: "FederationGuest" } & Pick<
      FederationGuest,
      | "id"
      | "federationName"
      | "operatorName"
      | "countryCode"
      | "mcc"
      | "mncs"
      | "parentSite"
      | "status"
    >
  >;
};

export type EditFederationHostMutationVariables = Exact<{
  host: EditFederationHostInput;
}>;

export type EditFederationHostMutation = { __typename?: "Mutation" } & {
  editFederationHost?: Maybe<
    { __typename?: "FederationHost" } & Pick<
      FederationHost,
      | "id"
      | "federationName"
      | "operatorName"
      | "countryCode"
      | "mcc"
      | "mncs"
      | "hostUrl"
      | "siteId"
      | "status"
    >
  >;
};

export type FederationGuestQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FederationGuestQuery = { __typename?: "Query" } & {
  federationGuest?: Maybe<
    { __typename?: "FederationGuest" } & Pick<
      FederationGuest,
      | "id"
      | "federationName"
      | "operatorName"
      | "countryCode"
      | "mcc"
      | "mncs"
      | "parentSite"
      | "status"
    >
  >;
};

export type FederationGuestsQueryVariables = Exact<{ [key: string]: never }>;

export type FederationGuestsQuery = { __typename?: "Query" } & {
  federationGuests?: Maybe<
    Array<
      Maybe<
        { __typename?: "FederationGuest" } & Pick<
          FederationGuest,
          "id" | "federationName" | "operatorName" | "countryCode" | "mcc" | "status"
        >
      >
    >
  >;
};

export type FederationHostQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FederationHostQuery = { __typename?: "Query" } & {
  federationHost?: Maybe<
    { __typename?: "FederationHost" } & Pick<
      FederationHost,
      | "id"
      | "federationName"
      | "operatorName"
      | "countryCode"
      | "mcc"
      | "mncs"
      | "hostUrl"
      | "siteId"
      | "status"
    >
  >;
};

export type FederationHostsQueryVariables = Exact<{ [key: string]: never }>;

export type FederationHostsQuery = { __typename?: "Query" } & {
  federationHosts?: Maybe<
    Array<
      Maybe<
        { __typename?: "FederationHost" } & Pick<
          FederationHost,
          "id" | "federationName" | "operatorName" | "countryCode" | "mcc" | "status"
        >
      >
    >
  >;
};

export type FetchParentSiteQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchParentSiteQuery = { __typename?: "Query" } & {
  site?: Maybe<
    { __typename?: "Site" } & Pick<Site, "id" | "displayName"> & {
        org?: Maybe<{ __typename?: "Org" } & Pick<Org, "id" | "displayName">>;
      }
  >;
};

export type OrgIdentityQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OrgIdentityQuery = { __typename?: "Query" } & {
  org?: Maybe<
    { __typename?: "Org" } & Pick<Org, "id"> & {
        federation?: Maybe<
          { __typename?: "Federation" } & Pick<
            Federation,
            "operatorName" | "countryCode" | "mcc" | "mncs" | "federationUrl"
          >
        >;
      }
  >;
};

export type RemoveFederationGuestMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveFederationGuestMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "removeFederationGuest"
>;

export type RemoveFederationHostMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveFederationHostMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "removeFederationHost"
>;

export type FetchOrganizationsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchOrganizationsQuery = { __typename?: "Query" } & {
  orgs?: Maybe<
    Array<Maybe<{ __typename?: "Org" } & Pick<Org, "id" | "displayName" | "description">>>
  >;
};

export type GenerateRecoveryLinkMutationVariables = Exact<{
  user: Scalars["ID"];
}>;

export type GenerateRecoveryLinkMutation = { __typename?: "Mutation" } & {
  generateRecoveryLink?: Maybe<
    { __typename?: "RecoveryLink" } & Pick<RecoveryLink, "link" | "expiresAt">
  >;
};

export type SetMapMutationVariables = Exact<{
  center: LatLngInput;
  zoom: Scalars["Int"];
}>;

export type SetMapMutation = { __typename?: "Mutation" } & {
  setDefaultMapPosition?: Maybe<
    { __typename?: "MapSettings" } & Pick<MapSettings, "zoom"> & {
        center?: Maybe<{ __typename?: "LatLng" } & Pick<LatLng, "lat" | "lng">>;
      }
  >;
};

export type OrgSelectQueryVariables = Exact<{ [key: string]: never }>;

export type OrgSelectQuery = { __typename?: "Query" } & {
  orgs?: Maybe<Array<Maybe<{ __typename?: "Org" } & Pick<Org, "id" | "displayName">>>>;
};

export type MapSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type MapSettingsQuery = { __typename?: "Query" } & {
  settings?: Maybe<
    { __typename?: "Settings" } & {
      map?: Maybe<
        { __typename?: "MapSettings" } & Pick<MapSettings, "zoom"> & {
            center?: Maybe<{ __typename?: "LatLng" } & Pick<LatLng, "lat" | "lng">>;
          }
      >;
    }
  >;
};

export type UserNameQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UserNameQuery = { __typename?: "Query" } & {
  user?: Maybe<{ __typename?: "User" } & Pick<User, "id" | "name">>;
};

export type UserOrgQueryVariables = Exact<{ [key: string]: never }>;

export type UserOrgQuery = { __typename?: "Query" } & {
  user?: Maybe<
    { __typename?: "User" } & {
      loggedInOrg?: Maybe<{ __typename?: "Org" } & Pick<Org, "id" | "displayName">>;
    }
  >;
};

export type AddUserMutationVariables = Exact<{
  name: Scalars["String"];
  email: Scalars["String"];
  org: Scalars["ID"];
  role: Role;
}>;

export type AddUserMutation = { __typename?: "Mutation" } & {
  createUser?: Maybe<
    { __typename?: "CreateUserResponse" } & {
      user?: Maybe<{ __typename?: "User" } & Pick<User, "id" | "name" | "email">>;
      error?: Maybe<{ __typename?: "Error" } & Pick<Error, "code" | "message">>;
    }
  >;
};

export type AllUsersTableQueryVariables = Exact<{ [key: string]: never }>;

export type AllUsersTableQuery = { __typename?: "Query" } & {
  users?: Maybe<
    Array<
      Maybe<
        { __typename?: "User" } & Pick<User, "id" | "name" | "email"> & {
            orgRoles?: Maybe<
              Array<
                Maybe<
                  { __typename?: "OrgRole" } & Pick<OrgRole, "id" | "role"> & {
                      org?: Maybe<{ __typename?: "Org" } & Pick<Org, "id" | "displayName">>;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteUserMutation = { __typename?: "Mutation" } & Pick<Mutation, "removeUser">;

export type DismissUserMutationVariables = Exact<{
  id: Scalars["ID"];
  orgId: Scalars["ID"];
}>;

export type DismissUserMutation = { __typename?: "Mutation" } & Pick<Mutation, "dismissUser">;

export type EditUserRoleMutationVariables = Exact<{
  user: Scalars["ID"];
  org: Scalars["ID"];
  role: Role;
}>;

export type EditUserRoleMutation = { __typename?: "Mutation" } & {
  setRole?: Maybe<
    { __typename?: "User" } & Pick<User, "id" | "name" | "email"> & {
        orgRoles?: Maybe<Array<Maybe<{ __typename?: "OrgRole" } & Pick<OrgRole, "id" | "role">>>>;
      }
  >;
};

export type EditableUserQueryVariables = Exact<{
  user: Scalars["ID"];
  org?: Maybe<Scalars["ID"]>;
}>;

export type EditableUserQuery = { __typename?: "Query" } & {
  user?: Maybe<
    { __typename?: "User" } & Pick<User, "id" | "name" | "email"> & {
        orgRoles?: Maybe<Array<Maybe<{ __typename?: "OrgRole" } & Pick<OrgRole, "id" | "role">>>>;
      }
  >;
};

export type FetchUserRoleQueryVariables = Exact<{ [key: string]: never }>;

export type FetchUserRoleQuery = { __typename?: "Query" } & {
  user?: Maybe<{ __typename?: "User" } & Pick<User, "id" | "loggedInRole">>;
};

export type OrgNameQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OrgNameQuery = { __typename?: "Query" } & {
  org?: Maybe<{ __typename?: "Org" } & Pick<Org, "id" | "displayName">>;
};

export type OrgUsersTableQueryVariables = Exact<{
  org: Scalars["ID"];
}>;

export type OrgUsersTableQuery = { __typename?: "Query" } & {
  org?: Maybe<
    { __typename?: "Org" } & Pick<Org, "id"> & {
        users?: Maybe<
          Array<
            Maybe<
              { __typename?: "User" } & Pick<User, "id" | "name" | "email"> & {
                  orgRoles?: Maybe<
                    Array<Maybe<{ __typename?: "OrgRole" } & Pick<OrgRole, "id" | "role">>>
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type RecruitUserMutationVariables = Exact<{
  user: Scalars["ID"];
  org: Scalars["ID"];
  role: Role;
}>;

export type RecruitUserMutation = { __typename?: "Mutation" } & {
  recruitUser?: Maybe<{ __typename?: "User" } & Pick<User, "id" | "name" | "email">>;
};

export type RecruitableUserQueryVariables = Exact<{
  user: Scalars["ID"];
}>;

export type RecruitableUserQuery = { __typename?: "Query" } & {
  user?: Maybe<{ __typename?: "User" } & Pick<User, "id" | "name" | "email">>;
};

export type RemoveUserMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveUserMutation = { __typename?: "Mutation" } & Pick<Mutation, "removeUser">;

export type SelectOrgMutationVariables = Exact<{
  org: Scalars["ID"];
}>;

export type SelectOrgMutation = { __typename?: "Mutation" } & {
  setCurrentOrg?: Maybe<
    { __typename?: "User" } & Pick<User, "id" | "loggedInRole"> & {
        loggedInOrg?: Maybe<{ __typename?: "Org" } & Pick<Org, "id" | "displayName">>;
      }
  >;
};

export type UserRoleQueryVariables = Exact<{ [key: string]: never }>;

export type UserRoleQuery = { __typename?: "Query" } & {
  user?: Maybe<
    { __typename?: "User" } & Pick<User, "id" | "loggedInRole"> & {
        loggedInOrg?: Maybe<{ __typename?: "Org" } & Pick<Org, "id" | "displayName">>;
        orgRoles?: Maybe<
          Array<
            Maybe<
              { __typename?: "OrgRole" } & Pick<OrgRole, "id"> & {
                  org?: Maybe<{ __typename?: "Org" } & Pick<Org, "id" | "displayName">>;
                }
            >
          >
        >;
      }
  >;
};

export type UserOrgsQueryVariables = Exact<{ [key: string]: never }>;

export type UserOrgsQuery = { __typename?: "Query" } & {
  user?: Maybe<
    { __typename?: "User" } & Pick<User, "id"> & {
        orgRoles?: Maybe<
          Array<
            Maybe<
              { __typename?: "OrgRole" } & Pick<OrgRole, "id"> & {
                  org?: Maybe<{ __typename?: "Org" } & Pick<Org, "id" | "displayName">>;
                }
            >
          >
        >;
      }
  >;
};

export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: "INTERFACE",
        name: "AuditLogEntity",
        possibleTypes: [
          {
            name: "BlockChart"
          },
          {
            name: "Device"
          },
          {
            name: "FederationGuest"
          },
          {
            name: "FederationHost"
          },
          {
            name: "GenericAuditLogEntity"
          },
          {
            name: "Org"
          },
          {
            name: "Provision"
          },
          {
            name: "ServiceChain"
          },
          {
            name: "Site"
          },
          {
            name: "User"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "SiteTreeItem",
        possibleTypes: [
          {
            name: "Org"
          },
          {
            name: "Site"
          }
        ]
      }
    ]
  }
};
export default result;
