import React, { FormEvent, ReactNode } from "react";
import styled, { css } from "styled-components";

import {
  LoginFlow,
  RecoveryFlow,
  SettingsFlow,
  UpdateLoginFlowBody,
  UpdateRecoveryFlowBody,
  UpdateSettingsFlowBody
} from "@ory/client";

import { KratosMessages } from "./KratosMessages";

const SettingsForm = css`
  width: 100%;
  margin: 0;
  grid-template-columns: repeat(3, 1fr);
`;

const StyledForm = styled.form<{ $settings?: boolean }>`
  display: grid;
  grid-row-gap: 20px;
  text-align: start;
  width: 320px;
  margin: 0 auto;
  ${({ $settings }) => $settings && SettingsForm}
`;

export type Values = Partial<UpdateLoginFlowBody | UpdateRecoveryFlowBody | UpdateSettingsFlowBody>;

interface FlowProps {
  onSubmit: (e: MouseEvent | FormEvent) => void;
  flow?: LoginFlow | SettingsFlow | RecoveryFlow;
  hideGlobalMessages?: boolean;
  children: ReactNode;
  isSettingsPage?: boolean;
}

export function Flow({
  onSubmit,
  flow,
  hideGlobalMessages = false,
  children,
  isSettingsPage = false
}: FlowProps) {
  return (
    <StyledForm
      action={flow?.ui.action}
      method={flow?.ui.method}
      onSubmit={onSubmit}
      $settings={isSettingsPage}
    >
      {!hideGlobalMessages && <KratosMessages messages={flow?.ui.messages} />}
      {children}
    </StyledForm>
  );
}
