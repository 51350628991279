import React from "react";
import styled from "styled-components";

import NoNetwork from "@img/no-network.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20%;
`;
const NoNetworkIcon = styled(NoNetwork)`
  height: 200px;
  fill: #999;
`;

const Message = styled.p`
  font-size: 16px;
  font-weight: 500;
`;

export function NoNetworkMessage() {
  return (
    <Wrapper>
      <NoNetworkIcon />
      <Message>No networks available at the moment.</Message>
    </Wrapper>
  );
}
