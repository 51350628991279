import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { useOrgInfo } from "@src/Hooks/orgInfo";

const Img = styled.img`
  align-self: center;
`;

const logoUrl = "/upload/logo.png";

export function ClientLogo() {
  const { orgId } = useOrgInfo();

  const img = useRef<HTMLImageElement>();

  useEffect(() => {
    if (orgId && img.current) {
      fetch(logoUrl, { cache: "reload" })
        .then(body => body.blob())
        .then(blob => {
          if (img.current) {
            img.current.src = URL.createObjectURL(blob);
          }
        });
    }
  }, [orgId]);

  return orgId ? <Img ref={img} alt="client logo" /> : null;
}
