import React from "react";

import NetworkIcon from "@img/network.svg";

import PublisherIcon from "../img/cloud-arrow-up-solid.svg";
import ServiceDesignerIcon from "../img/project-diagram-solid.svg";
import ReportIcon from "../img/reports.svg";
import ServicesIcon from "../img/services.svg";
import SettingsIcon from "../img/system.svg";
import { NavIcons } from "./Components/Navigation/NavbarStyles";
import { NavItem } from "./Components/Navigation/NavItem";
import { useIsAdmin } from "./Hooks/isAdmin";
import { useIsMno } from "./Hooks/isMno";
import { useIsSuperAdmin } from "./Hooks/isSuperAdmin";

export function ServicesNavbar() {
  const { isMno } = useIsMno();
  const { isAdmin } = useIsAdmin();
  const { isSuperAdmin } = useIsSuperAdmin();

  return (
    <>
      <NavIcons>
        <NavItem
          disabled={isSuperAdmin}
          linkTo="/app/services"
          label="Services"
          icon={ServicesIcon}
        />
        <NavItem
          disabled={isSuperAdmin}
          linkTo="/app/service-designer"
          label="Designer"
          icon={ServiceDesignerIcon}
        />
        <NavItem
          disabled={!isAdmin || isSuperAdmin}
          linkTo="/app/publisher"
          label="Publisher"
          icon={PublisherIcon}
        />
        <NavItem
          disabled={isSuperAdmin}
          linkTo="/app/infrastructure"
          label="Infrastructure"
          icon={NetworkIcon}
        />
        <NavItem disabled={isSuperAdmin} linkTo="/app/reports" label="Reports" icon={ReportIcon} />
        <NavItem
          disabled={!isSuperAdmin && !isAdmin && !isMno}
          linkTo="/app/settings"
          label="Settings"
          icon={SettingsIcon}
        />
      </NavIcons>
    </>
  );
}
