import "react-loading-skeleton/dist/skeleton.css";

import React, { lazy, Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";

import { Licenses } from "./About/Licenses";
import { LicensesView } from "./About/LicensesView";
import { LicensesWeb } from "./About/LicensesWeb";
import { Error } from "./Auth/Error";
import { Login } from "./Auth/Login";
import { Recover } from "./Auth/Recover";
import { LoadingPage } from "./Components/Loading/LoadingPage";
import { NotFoundPage } from "./Components/Navigation/NotFoundPage";
import { ProtectedDashboard } from "./Components/Navigation/ProtectedRoute";
import { ErrorReviewPage } from "./Error/ErrorPage";
import { NoDashboard } from "./Error/NoDashboard";
import { InitialRedirect } from "./InitialRedirect";
import { Layout } from "./Layout";
import { UserSettings } from "./User/UserSettings";

const Auth = lazy(() => import("./Auth/Auth"));
const AboutPage = lazy(() => import("./About/AboutPage"));
const MPNRoutes = lazy(() => import("./MobilePrivateNetwork/MPNRoutes"));
const ServicesDashboardRoutes = lazy(() => import("./ServicesDashboardRoutes"));

export function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<InitialRedirect />} />
          <Route path="app/no-dashboard" element={<NoDashboard />} />

          <Route
            path="/*"
            element={
              <ProtectedDashboard dashboard="services">
                <ServicesDashboardRoutes />
              </ProtectedDashboard>
            }
          />
          <Route
            path="app/mpn/*"
            element={
              <ProtectedDashboard dashboard="mpn">
                <MPNRoutes />
              </ProtectedDashboard>
            }
          />

          <Route path="app/about">
            <Route index element={<AboutPage />} />
            <Route path="licenses" element={<LicensesView />}>
              <Route path="web" element={<LicensesWeb />} />
              <Route path=":component" element={<Licenses />} />
            </Route>
          </Route>

          <Route path="user/settings" element={<UserSettings />} />
          <Route path="app/error" element={<ErrorReviewPage />} />
        </Route>

        <Route
          path="/auth"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Auth />
            </Suspense>
          }
        >
          <Route path="login" element={<Login />} />
          <Route path="recovery" element={<Recover />} />
          <Route path="error" element={<Error />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Toaster />
    </>
  );
}
