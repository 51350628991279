import React, { InputHTMLAttributes } from "react";
import { IStyledComponent } from "styled-components";

import { UiNode, UiNodeInputAttributes } from "@ory/client";

interface NodeInputProps {
  node: UiNode;
  attributes: UiNodeInputAttributes;
  disabled: boolean;
  value: string;
  placeholder: string;
  setValue: (value: string) => void;
  input: IStyledComponent<"web", InputHTMLAttributes<HTMLInputElement>>;
}

export function NodeInput({
  node,
  attributes,
  value,
  disabled,
  input,
  placeholder,
  setValue
}: NodeInputProps) {
  const textValue = attributes.type === "submit" ? node.meta.label?.text : value;
  const Input = input;
  return (
    <Input
      placeholder={placeholder}
      title={node.meta.label?.text}
      type={attributes.type}
      name={attributes.name}
      value={textValue}
      onChange={e => setValue(e.target.value)}
      disabled={attributes.disabled || disabled}
    />
  );
}
