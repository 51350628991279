import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { Identity, Session } from "@ory/client";
import { kratos } from "@src/Auth/kratos";

import { RenewSessionModal } from "./Auth/RenewSessionModal";
import { sessionExpiryCheck } from "./Auth/sessionExpiryCheck";
import { Modal } from "./Components/Modal/Modal";
import { ToastNotification } from "./Components/ToastNotification";
import { useToggle } from "./Hooks/toggle";

interface User {
  email: string;
  name: string;
}

export interface UserSession extends Session {
  identity: Identity & {
    traits: User;
  };
}

interface SessionState {
  session: UserSession | undefined;
  logoutSession: () => void;
  setOrgSession: (orgId: string) => void;
  isLoggedOut: boolean;
}

const SessionContext = createContext<SessionState>({
  session: undefined,
  isLoggedOut: false,
  logoutSession: Function,
  setOrgSession: Function
});

export const useSession = () => useContext(SessionContext);

/* eslint-disable camelcase */
export const mockSession: UserSession = {
  id: "ac534cd6-0de7-4356-bbe3-41dd7af87250",
  active: true,
  expires_at: "2021-01-20T10:37:46.71667Z",
  authenticated_at: "2021-01-19T10:37:46.71667Z",
  issued_at: "2021-01-19T10:37:46.716698Z",
  identity: {
    id: "FAKE-ID",
    schema_id: "default",
    schema_url: "https://demo.nearbycomputing.com/.ory/kratos/public/schemas/default",
    traits: { name: "Tom Fenech", email: "tfenech@nearbycomputing.com" },
    metadata_public: {
      org: "d1810067-30ec-4e0d-afb5-783e60b4ba4e"
    },
    verifiable_addresses: [
      {
        id: "10842626-a7d8-49cb-b013-3caabdf07265",
        value: "tfenech@nearbycomputing.com",
        verified: false,
        via: "email",
        status: "pending",
        verified_at: null
      }
    ],
    recovery_addresses: [
      {
        id: "bff9e67c-d159-42c0-a2a3-47f7003fda99",
        value: "tfenech@nearbycomputing.com",
        via: "email"
      }
    ]
  }
};
/* eslint-enable camelcase */

interface SessionProviderProps {
  children: ReactNode;
  overwriteMock?: UserSession;
}

export function SessionProvider({ children, overwriteMock }: SessionProviderProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isInit, setIsInit] = useState(true);

  const [hasSession, setHasSession] = useState(false);
  const [session, setSession] = useState<UserSession | undefined>(
    overwriteMock ? overwriteMock : MOCK_USERINFO ? mockSession : null
  );

  const { state: show, on: open, off: close } = useToggle();

  const setOrgSession = useCallback(
    (orgId: string) => {
      setSession(session => ({
        ...session,
        identity: {
          ...session?.identity,
          metadata_public: {
            ...session?.identity?.metadata_public,
            org: orgId
          }
        }
      }));
    },
    [setSession]
  );

  const logoutSession = useCallback(() => {
    setSession(undefined);
  }, [setSession]);

  const sessionCheck = useCallback(
    (currentDate: string, expiresAt: string) => {
      if (sessionExpiryCheck(currentDate, expiresAt))
        toast(
          <ToastNotification
            title="⚠️ Session about to expire ⚠️"
            msg="Click here to renew it"
            onClick={open}
          />,
          {
            id: "session"
          }
        );
    },
    [open]
  );

  const sessionExpiredToast = useCallback(() => {
    if (!pathname.includes("auth"))
      toast(
        <ToastNotification
          title="❌ Your Session has expired ❌"
          msg="Click here to go back to the login screen"
          onClick={() => {
            navigate("/auth/login");
            toast.dismiss();
          }}
        />,
        {
          id: "session-expired"
        }
      );
  }, [navigate, pathname]);

  useEffect(() => {
    if (MOCK_USERINFO || overwriteMock) return;

    kratos
      .toSession()
      .then(res => {
        const sessionData = res.data as UserSession;
        setSession(sessionData);
        setHasSession(true);
        sessionCheck(res.headers.date, sessionData.expires_at);
      })
      .catch(err => {
        switch (err.response?.status) {
          case 403:
          // This is a legacy error code thrown. See code 422 for
          // more details.
          case 422:
            // This status code is returned when we are trying to
            // validate a session which has not yet completed
            // its second factor
            navigate("/auth/login");
          case 401:
            // if user is on non auth view, notify them
            console.log(err);
            sessionExpiredToast();
            return;
        }
      })
      .finally(() => setIsInit(false));
  }, [navigate, overwriteMock, sessionExpiredToast, sessionCheck]);

  return (
    <SessionContext.Provider
      value={{ session, setOrgSession, logoutSession, isLoggedOut: !isInit && !hasSession }}
    >
      {children}
      <Modal show={show} onOutsideClick={close}>
        <RenewSessionModal closeModal={close} />
      </Modal>
    </SessionContext.Provider>
  );
}
