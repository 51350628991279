import React from "react";

import MonitoringIcon from "@img/monitoring.svg";
import ReportsIcon from "@img/reports.svg";
import TroubleshootIcon from "@img/screwdriver-wrench-solid.svg";
import SimCardIcon from "@img/sim-card.svg";
import ConfigureIcon from "@img/sliders-solid.svg";
import SettingsIcon from "@img/system.svg";
import UserIcon from "@img/user-filled.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";

export function MPNNavbar() {
  return (
    <NavIcons>
      <NavItem linkTo="app/mpn/operate" label="Operate" icon={MonitoringIcon} />
      <NavItem linkTo="app/mpn/configure" label="Configure" icon={ConfigureIcon} />
      <NavItem linkTo="app/mpn/sim-cards" label="SIMs" icon={SimCardIcon} />
      <NavItem linkTo="app/mpn/troubleshoot" label="Troubleshoot" icon={TroubleshootIcon} />
      <NavItem linkTo="app/mpn/access" label="Access" icon={UserIcon} />
      <NavItem linkTo="app/mpn/reports" label="Reports" icon={ReportsIcon} />
      <NavItem linkTo="/app/mpn/settings" label="Settings" icon={SettingsIcon} />
    </NavIcons>
  );
}
