import { usePermissionCheck } from "./permissionsCheck";

export function useIsSuperAdmin() {
  const { allowed, loading } = usePermissionCheck({
    relation: "superAdmin",
    objectType: "envs",
    objectId: "nearbyone"
  });

  return { isSuperAdmin: allowed, loading };
}
