import { usePermissionCheck } from "./permissionsCheck";

export function useServicesDashboard() {
  const { allowed, loading } = usePermissionCheck({
    relation: "view",
    objectType: "dashboards",
    objectId: "services"
  });

  return { allowed, loading };
}
