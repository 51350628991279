import React, { ChangeEvent, KeyboardEvent, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { Input } from "@src/Components/Input/Input";

import { useLogNavigation } from "./logNavigation";

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const Label = styled.label`
  font-weight: 600;
  font-size: small;
  white-space: nowrap;
  margin-right: 10px;
`;

const ButtonPageSize = styled(PrimaryButton)`
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  width: auto;
  padding: 0 10px;
`;

const SizeInput = styled(Input)`
  width: 100px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
`;
export function PageSizeInput() {
  const updateQueryParams = useLogNavigation();

  const [searchParams] = useSearchParams();
  const pageSizeParam = searchParams.get("pageSize");

  const [pageSize, setPageSize] = useState(pageSizeParam || "");

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPageSize(e.target.value);
    },
    [setPageSize]
  );

  const handleUpdate = useCallback(() => {
    updateQueryParams({ pageSize: Number(pageSize) });
  }, [pageSize, updateQueryParams]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key == "Enter") {
        event.preventDefault();
        handleUpdate();
      }
    },
    [, handleUpdate]
  );

  return (
    <Wrapper>
      <Label>Page size:</Label>
      <SizeInput
        type="number"
        min={1}
        onKeyPress={onKeyDown}
        value={pageSize}
        onChange={handleInputChange}
      />
      <ButtonPageSize onClick={handleUpdate}>Apply</ButtonPageSize>
    </Wrapper>
  );
}
