import React from "react";
import { Navigate } from "react-router-dom";

import { useIsMno } from "./Hooks/isMno";
import { useIsSuperAdmin } from "./Hooks/isSuperAdmin";
import { useMPNDashboard } from "./Hooks/mpnDashboard";
import { useServicesDashboard } from "./Hooks/servicesDashboard";

export function InitialRedirect() {
  const { isMno } = useIsMno();
  const { isSuperAdmin } = useIsSuperAdmin();

  const { allowed: allowMPN } = useMPNDashboard();
  const { allowed: allowServices } = useServicesDashboard();

  if (isMno == null || isSuperAdmin == null || allowMPN == null || allowServices == null)
    return null;

  const redirectString = allowServices
    ? isSuperAdmin
      ? "app/settings/user-management"
      : isMno
      ? "app/settings/federation"
      : "app/services"
    : allowMPN
    ? "app/mpn"
    : "app/no-dashboard";

  return <Navigate to={redirectString} />;
}
