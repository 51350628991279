import axios from "axios";
import { UseSelectStateChange } from "downshift";
import React, { useCallback, useEffect } from "react";
import { useQuery as useReactQuery } from "react-query";
import styled from "styled-components";

import { Dropdown, Select } from "@src/Components/Dropdown";
import { SelectorWrapper } from "@src/Components/OrgSelector";
import { HeaderItem } from "@src/Header/HeaderStyles";

const NetworkSelect = styled(Select)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  height: auto;
  font-size: 12px;
`;

interface NetworkField {
  name: string;
  value: string | number;
}
interface NetworkResponse {
  resourceCharacteristic: NetworkField[];
}

export interface SerialisedNetwork {
  displayname: string;
  cores: number;
  readycores: number;
}

const fetchNetworks = (): Promise<NetworkResponse[]> =>
  axios
    .get(
      "/mobile-networks/network/tmf-api/resourceInventoryManagement/v4/resource?fields=a&offset=0&limit=0"
    )
    .then(response => response?.data);

export function useFetchNetworks() {
  const { data, isLoading: loading } = useReactQuery([`network-fetch`], () => fetchNetworks());
  return { data: data || [], loading };
}

export interface NetworkSelectorProps {
  selectedNetwork: SerialisedNetwork;
  setSelectedNetwork: (network: SerialisedNetwork) => void;
  simData: NetworkResponse[];
}

export function NetworkSelector({
  selectedNetwork,
  setSelectedNetwork,
  simData
}: NetworkSelectorProps) {
  const networkData = serialiseNetworksData(
    (simData || []).map(el => el?.resourceCharacteristic)
  ) as SerialisedNetwork[];

  useEffect(() => {
    if (networkData.length >= 1 && !selectedNetwork) setSelectedNetwork(networkData[0]);
  }, [setSelectedNetwork, networkData, selectedNetwork, simData]);

  const onSelectSite = useCallback(
    (change: UseSelectStateChange<SerialisedNetwork>) => {
      setSelectedNetwork(change.selectedItem);
    },
    [setSelectedNetwork]
  );

  const getItemLabel = useCallback((selectedItem: SerialisedNetwork) => {
    return selectedItem?.displayname ?? "No Network";
  }, []);

  if (!selectedNetwork) return null;

  return (
    <HeaderItem>
      Network:
      <SelectorWrapper>
        <Dropdown
          SelectElement={NetworkSelect}
          items={networkData}
          initialSelectedItem={selectedNetwork}
          onSelectedItemChange={onSelectSite}
          getItemDisplayName={getItemLabel}
          getItemKey={getItemLabel}
        />
      </SelectorWrapper>
    </HeaderItem>
  );
}

function serialiseNetworksData(networkFields: NetworkField[][]) {
  return networkFields.map(arr => {
    const network = arr.reduce((acc, el) => {
      return {
        ...acc,
        [el.name.toLowerCase()]: el.value
      };
    }, {});
    return network;
  });
}
